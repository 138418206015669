import { layout } from '../../styles';

export default theme => ({
  container: {
    display: layout.fx,
    // height: '100vh',
    // backgroundColor: 'yellow',
    // height: 'calc(100vh - 64px)',
    // paddingTop: 64,
    // overflow: 'hidden',
    position: 'absolute',
    top: 64,
    left: 0,
    right: 0,
    bottom: 0,
  },
  side: {
    overflow: 'scroll',
    width: layout.hvw
  },
  full: {
    [theme.breakpoints.down('sm')]: {
      width: `${layout.fs} !important`,
      display: 'block !important',
    },
  },
  dissapear: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  leftSide: {
    width: layout.hvw,
    [theme.breakpoints.down('sm')]: {
      width: layout.fs,
    },
  },
  rightSide: {
    width: layout.hvw,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  }
})