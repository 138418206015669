export const LOAD_NOT_AT_HOME = 'app/not_at_home/load_nah_schedule';
export const LOAD_NOT_AT_HOME_COMPLETE = 'app/not_at_home/load_nah_schedule_complete';
export const LOAD_NOT_AT_HOME_FAIL = 'app/not_at_home/load_nah_schedule_fail';

export const ADD_NOT_AT_HOME = 'app/not_at_home/add_nah';
export const ADD_NOT_AT_HOME_COMPLETE = 'app/not_at_home/add_nah_complete';
export const ADD_NOT_AT_HOME_FAIL = 'app/not_at_home/add_nah_fail';

export const DELETE_NOT_AT_HOME = 'app/not_at_home/delete_nah';
export const DELETE_NOT_AT_HOME_COMPLETE = 'app/not_at_home/delete_nah_complete';
export const DELETE_NOT_AT_HOME_FAIL = 'app/not_at_home/delete_nah_fail';

export const ADD_SELECTED_HOME = 'app/not_at_home/add_selected_home';
export const SET_SELECTED_HOMES = 'app/not_at_home/set_selected_homes';
export const SET_ACTIVE_CARD = 'app/not_at_home/set_active_card';
export const SET_REFRESH = 'app/not_at_home/set_refresh';
export const CHECK_OUT_TERRITORY = 'app/not_at_home/check_out_territory';
