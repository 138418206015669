import { combineReducers } from 'redux';
import * as types from './types';

/* State shape
{
	myNotAtHomes: [],
}
*/

const myNotAtHomes = (state = [], action) => {
	switch (action.type) {
		case types.LOAD_MY_NOT_AT_HOME_COMPLETE:
			return [...state, ...action.payload];
		case types.DELETE_MY_NOT_AT_HOME:
			return state.filter(n => n.id !== action.payload);
		default:
			return state;
	}
};

const reducer = combineReducers({
	myNotAtHomes,
});

export default reducer;
