import React  from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { formErrors } from '../../utils';
import { commonOperations } from '../../state/ducks/Common';
import users from './users';

import Login from './content';

const initialValues = {
  user: users[0],
  code: '',
};

const schema = Yup.object({
  user: Yup.string(formErrors.string).required(formErrors.required),
  code: Yup.string(formErrors.string).required(formErrors.required),
});

const NewLoginForm = props => {
  const { login, congregationId } = props;
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, actions) => {
          const { user, code } = values;
          const email = `${congregationId}_${user}@taichungministry.com`;
          
          const onSuccess = () => {
            actions.setSubmitting(false);
            actions.resetForm(initialValues);
          };

          const onError = e => {
            actions.resetForm(initialValues);
            actions.setStatus(e.message);
            actions.setSubmitting(false);
          };

          login(email, code, onSuccess, onError);
        }}
        render={(formikProps) => <Login {...formikProps} />}
      />
    )
};

NewLoginForm.propTypes = {
  login: Proptypes.func.isRequired,
  congregationId: Proptypes.string.isRequired,
  common: Proptypes.objectOf(Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
    Proptypes.bool,
    Proptypes.object
  ])),
};

NewLoginForm.defaultProps = {
  common: {},
};

const mapStateToProps = state => {
	const { common } = state;
	return common;
};

const mapDispatchToProps = {
	login: commonOperations.login,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLoginForm);