
import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

const Snack = props => {
  const { open, message, handleClose } = props;
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{message}</span>}
      />
    </div>
  );
}

Snack.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  handleClose: PropTypes.func.isRequired,
};

Snack.defaultProps = {
  open: false,
  message: '',
};


export default Snack;