import React from 'react';
import PropTypes from 'prop-types';
import MDDialog from '@material-ui/core/Dialog';
import { padding } from '../../styles';

const Dialog = props => {
  const { children, ...rest } = props;

  return (
    <MDDialog
      {...rest}
      PaperProps={{ style: { margin: padding.sm }}}
    >
      {children}
    </MDDialog>
  );
}

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Dialog };