import React from 'react';
import { Translate } from 'react-localize-redux';

const translate = (id, props = {}) => <Translate id={id} {...props} />;

const onLanguageChange = callback => (prevProps, props) => {
  const { activeLanguage } = props;
  const prevLangCode = prevProps.activeLanguage && prevProps.activeLanguage.code;
  const curLangCode = activeLanguage && activeLanguage.code;
  const hasLanguageChanged = prevLangCode !== curLangCode;
  if (hasLanguageChanged) {
    callback(activeLanguage);
  }
}

const onChange = (prev, next, callback) => {
  if ((prev !== next) && next) {
    callback(next)
  }
}

export { translate, onLanguageChange, onChange };