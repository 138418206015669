import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { shadow } from '../../../../styles';

const SelectInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  formControl: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    minWidth: 120,
  },
  input: {
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    boxShadow: shadow,
    fontSize: 16,
    fontWeight: '700',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#fff',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

export default SelectInput;