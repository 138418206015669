import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const Split = (props) => {
  const { children, classes, responsiveShowLeftSide } = props;

  const desktop = _.isNull(responsiveShowLeftSide);

  const left = () => {
    if (desktop) {
      return classes.side
    }
    if (responsiveShowLeftSide) {
      return classes.full
    }
    if (!responsiveShowLeftSide) {
      return classes.dissapear
    }
    return classes.side
  };

  const right = () => {
    if (desktop) {
      return classes.side
    }
    if (responsiveShowLeftSide) {
      return classes.dissapear
    }
    if (!responsiveShowLeftSide) {
      return classes.full
    }
    return classes.side
  };

  return (
    <div className={classes.container}>
      <div className={classnames(classes.leftSide, left())}>
        {React.cloneElement(children[0])}
      </div>
      <div className={classnames(classes.rightSide, right())}>
        {React.cloneElement(children[1])}
      </div>
    </div>
  )
};

Split.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  responsiveShowLeftSide: PropTypes.bool,
}

Split.defaultProps = {
  responsiveShowLeftSide: null,
}

const mapStateToProps = state => {
	const { common } = state;
	return common;
};

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles)
);

const SplitScreen = enhance(Split);
export { SplitScreen };
