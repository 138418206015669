import React from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { routes } from '../../utils';

const LinkButton = withRouter(props => {
  const {
    children,
    history,
    link,
    className,
    location,
    match,
    congregationId
   } = props;
  const paths = location.pathname.split('/');
  const path = paths[paths.length - 1];

  const active = (path === link) || (path === congregationId && link === routes[0]);

  return (
    <Button
      size="large"
      color={active ? 'secondary' : 'inherit'}
      onClick={() => { history.push(`${match.url}/${link}`) }}
      className={className}
    >
      {children}
    </Button>
  );
});

export { LinkButton };