import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import green from '@material-ui/core/colors/green';

const colors = {
	blue: indigo.A400,
	red: pink.A400,
	charcoal: '#000000',
	grey: '#D8D8D8',
	green: green[400], 
	white: '#ffffff',
	transparent: 'transparent',
};

export { colors };

// #09af00 green