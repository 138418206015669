export default theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
  formControl: {
    margin: theme.spacing.unit,
    flexDirection: 'row',
    alignItems: 'center'
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
});