const loadScript = (url, lang='') => {
  const allScripts = window.document.getElementsByTagName('script');
  const index = allScripts[allScripts.length - 1];
  const script = window.document.createElement('script');
  script.src = url;
  if (lang) {
    script.src += `&language=${lang}`;
  }
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}


const loadGoogleMapScript = (lang, libraries, key) => {
  
  // Destroy old API
  document.querySelectorAll('script[src^="https://maps.googleapis.com"]').forEach(script => {
    script.remove();
  });

  if (window.google) delete window.google.maps;

  // Generate new Google Maps API script
  const newAPI = document.createElement('script');
  newAPI.src = `https://maps.googleapis.com/maps/api/js?libraries=${libraries.join(',')}&key=${key}&language=${lang}&callback=googleMapsAPILoaded`;

  // Callback for the Google Maps API src
  window.googleMapsAPILoaded = () => {
    const event = new CustomEvent('googleMapsAPILoaded');
    window.dispatchEvent(event);
  }

  // Wait for the callback to be executed
  const apiLoaded = new Promise(resolve => {
    window.addEventListener('googleMapsAPILoaded', () => {
      resolve();
    });
  });

  // Start the script
  document.querySelector('head').appendChild(newAPI);

  return apiLoaded;
}

export { loadScript, loadGoogleMapScript };