import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { LocalizeProvider } from 'react-localize-redux';
import { store, persistor } from './state/store';
import Router from './router/index';
import { theme } from './styles';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <LocalizeProvider store={store}>
            <Router />
          </LocalizeProvider>
        </BrowserRouter>
      </MuiThemeProvider>
    </PersistGate>
  </Provider>
);

export default App;
