import * as types from './types';

const loadServiceSchedule = () => ({
	type: types.LOAD_SERVICE_SCHEDULE,
});

const loadServiceScheduleComplete = Languages => ({
	type: types.LOAD_SERVICE_SCHEDULE_COMPLETE,
	payload: Languages,
});

const loadServiceScheduleError = error => ({
	type: types.LOAD_SERVICE_SCHEDULE_FAIL,
	payload: error,
});

const openNewScheduleDialog = () => ({
	type: types.NEW_SCHEDULE_DIALOG_OPEN,
});

const closeNewScheduleDialog = () => ({
	type: types.NEW_SCHEDULE_DIALOG_CLOSE,
});

const addServiceSchedule = () => ({
	type: types.ADD_SERVICE_SCHEDULE,
});

const addServiceScheduleComplete = schedule => ({
	type: types.ADD_SERVICE_SCHEDULE_COMPLETE,
	payload: schedule,
});

const addServiceScheduleFail = error => ({
	type: types.ADD_SERVICE_SCHEDULE_FAIL,
	payload: error,
});

const deleteServiceSchedule = () => ({
	type: types.DELETE_SERVICE_SCHEDULE,
});

const deleteServiceScheduleComplete = schedule => ({
	type: types.DELETE_SERVICE_SCHEDULE_COMPLETE,
	payload: schedule,
});

const deleteServiceScheduleFail = error => ({
	type: types.DELETE_SERVICE_SCHEDULE_FAIL,
	payload: error,
});

export {
	loadServiceSchedule,
	loadServiceScheduleComplete,
	loadServiceScheduleError,
	openNewScheduleDialog,
	closeNewScheduleDialog,
	addServiceSchedule,
	addServiceScheduleComplete,
	addServiceScheduleFail,
	deleteServiceSchedule,
	deleteServiceScheduleComplete,
	deleteServiceScheduleFail
};
