import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import { layout } from '../../styles';

export default theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    boxShadow: layout.none
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: layout.fx,
    alignItems: layout.ct,
  },
});