import { CommonModel } from './common';

const SERVICE_SCHEDULE_COLLECTION = 'service';

const SERVICE_SCHEDULE_SCHEMA = {
  address: '',
  time: '',
  date: '',
  notes: ''
};

class ServiceScheduleService extends CommonModel {
  constructor() {
    super(SERVICE_SCHEDULE_COLLECTION, SERVICE_SCHEDULE_SCHEMA);
  }

  add(serviceSchedule) {
    return new Promise(async (resolve, reject) => {
      try {
        const { id } = await this.create(serviceSchedule);
        resolve(id);
      } catch (error) {
        reject(new Error(error.message));
      }
    });
  }

  update(serviceScheduleId, serviceSchedule) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.updateById(serviceScheduleId, serviceSchedule);
        resolve();
      } catch (error) {
        reject(new Error(error.message));
      }
    });
  }

  delete(serviceScheduleId) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.deleteById(serviceScheduleId);
        resolve();
      } catch (error) {
        reject(new Error(error.message));
      }
    });
  }

  getOne(serviceScheduleId) {
    return new Promise(async (resolve, reject) => {
      try {
        const serviceSchedule = await this.findById(serviceScheduleId);
        resolve(serviceSchedule);
      } catch (error) {
        reject(new Error(error.message));
      }
    });
  }

  getAll() {
    return new Promise(async (resolve, reject) => {
      let serviceSchedules = [];
      try {
        const querySnapshot = await this.firestore
          .collection(this.collection)
          .orderBy('date', this.verbage.asc)
          .get();

        querySnapshot.forEach(doc => {          
          const serviceSchedule = {
            id: doc.id,
            ref: doc.ref,
            ...doc.data()
          };

          serviceSchedules = [...serviceSchedules, serviceSchedule];
        });

        resolve(serviceSchedules);
      } catch (error) {
        reject(new Error(error.message));
      }
    });
  }
}

const ServiceSchedule = new ServiceScheduleService();
export { ServiceSchedule };
