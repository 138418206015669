import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from './list';
import { Button, Dialog } from '../..';
import { translate } from '../../../utils';

const DetailsDialog = props => {
  const { open, handleClose, activeCard } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translate('card_details')}</DialogTitle>
        <List {...activeCard} />
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {translate('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DetailsDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  activeCard: PropTypes.object.isRequired,
}

DetailsDialog.defaultProps = {
  open: false,
}

export default DetailsDialog;