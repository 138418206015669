import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { padding, layout } from '../../../styles';
import { Button } from '../../../components';


const styles = {
  checkoutContainer: {
    position: 'fixed',
    padding: padding.md,
    bottom: 0,
    left: layout.hs,
    transform: `translateX(-${layout.hs})`,
    zIndex: 999,
    width: layout.fs,
    textAlign: layout.ct
  },
  button: {
    boxShadow: '0px 3px 6px 1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    minWidth: 215
  }
}

const CheckoutButton = props => {
  const { classes, children, ...rest } = props;

  return (
    <div className={classes.checkoutContainer}>
      <Button
        size="large"
        variant="contained"
        color="primary"
        className={classes.button}
        {...rest}
      >
        {children}
      </Button>
    </div>
  )
}

CheckoutButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  classes: PropTypes.PropTypes.object.isRequired,
}

CheckoutButton.defaultProps = {
  children: '',
}

export default withStyles(styles)(CheckoutButton);