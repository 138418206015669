import { combineReducers } from 'redux';
import * as types from './types';

/* State shape
{
	serviceScheduleLoading: false,
	serviceSchedule: [],
	serviceScheduleError: '',

	deleteServiceScheduleLoading: false,
	deleteServiceSchedule: false,
	deleteServiceScheduleError: '',

	newScheduleDialogOpen: false,

	addScheduleLoading: false,
	addScheduleError: '',

	googleMapReference: null,
	googleSelectedPlace: {},
}
*/

const serviceScheduleLoading = (state = false, action) => {
	switch (action.type) {
		case types.LOAD_SERVICE_SCHEDULE:
			return true;
		case types.LOAD_SERVICE_SCHEDULE_FAIL:
			return false;
		case types.LOAD_SERVICE_SCHEDULE_COMPLETE:
			return false;
		default:
			return state;
	}
};

const serviceSchedule = (state = [], action) => {
	switch (action.type) {
		case types.LOAD_SERVICE_SCHEDULE_COMPLETE:
			return action.payload;
		case types.ADD_SERVICE_SCHEDULE_COMPLETE:
			return [action.payload, ...state];
		default:
			return state;
	}
};

const serviceScheduleError = (state = '', action) => {
	switch (action.type) {
		case types.LOAD_SERVICE_SCHEDULE:
			return '';
		case types.LOAD_SERVICE_SCHEDULE_COMPLETE:
			return '';
		case types.LOAD_SERVICE_SCHEDULE_FAIL:
			return action.payload;
		default:
			return state;
	}
};

const newScheduleDialogOpen = (state = false, action) => {
	switch (action.type) {
		case types.NEW_SCHEDULE_DIALOG_OPEN:
			return true;
		case types.NEW_SCHEDULE_DIALOG_CLOSE:
			return false;
		default:
			return state;
	}
};

const addScheduleLoading = (state = false, action) => {
	switch (action.type) {
		case types.ADD_SERVICE_SCHEDULE:
			return true;
		case types.ADD_SERVICE_SCHEDULE_FAIL:
			return false;
		case types.ADD_SERVICE_SCHEDULE_COMPLETE:
			return false;
		default:
			return state;
	}
};

const addScheduleError = (state = '', action) => {
	switch (action.type) {
		case types.ADD_SERVICE_SCHEDULE:
			return '';
		case types.ADD_SERVICE_SCHEDULE_COMPLETE:
			return '';
		case types.ADD_SERVICE_SCHEDULE_FAIL:
			return action.payload;
		default:
			return state;
	}
};

const deleteServiceScheduleLoading = (state = false, action) => {
	switch (action.type) {
		case types.DELETE_SERVICE_SCHEDULE:
			return true;
		case types.DELETE_SERVICE_SCHEDULE_COMPLETE:
			return false;
		case types.DELETE_SERVICE_SCHEDULE_FAIL:
			return false;
		default:
			return state;
	}
};

const deleteServiceSchedule = (state = false, action) => {
	switch (action.type) {
		case types.DELETE_SERVICE_SCHEDULE:
			return false;
		case types.DELETE_SERVICE_SCHEDULE_COMPLETE:
			return true;
		case types.DELETE_SERVICE_SCHEDULE_FAIL:
			return false;
		default:
			return state;
	}
};

const deleteServiceScheduleError = (state = false, action) => {
	switch (action.type) {
		case types.DELETE_SERVICE_SCHEDULE:
			return '';
		case types.DELETE_SERVICE_SCHEDULE_COMPLETE:
			return '';
		case types.DELETE_SERVICE_SCHEDULE_FAIL:
			return action.payload;
		default:
			return state;
	}
};

const reducer = combineReducers({
	serviceScheduleLoading,
	serviceSchedule,
	serviceScheduleError,
	newScheduleDialogOpen,
	addScheduleLoading,
	addScheduleError,
	deleteServiceScheduleLoading,
	deleteServiceSchedule,
	deleteServiceScheduleError
});

export default reducer;
