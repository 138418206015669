import React  from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { notAtHomeOperations } from '../../../state/ducks/NotAtHome';
import { formErrors } from '../../../utils';
import { commonOperations } from '../../../state/ducks/Common';

import NotAtHome from './content';

const initialValues = {
  address: '',
  // number: '',
  notes: ''
};

const schema = Yup.object({
  address: Yup.string(formErrors.string).required(formErrors.required),
  // number: Yup.number(formErrors.number),
  notes: Yup.string(formErrors.string),
});

const NewNotAtHomeForm = props => {
  const {
    addNotAtHome,
    closeDialog,
    setGoogleSelectedPlace,
    common: { googleSelectedPlace },
  } = props;
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, actions) => {
          let notAtHome = values;
          if (googleSelectedPlace && googleSelectedPlace.geometry) {
            const { geometry: { location } } = googleSelectedPlace;
            notAtHome = {
              ...values,
              location: { lat: location.lat(), lng: location.lng() }
            };
          }

          addNotAtHome(notAtHome, () => {
            actions.setSubmitting(false);
            actions.resetForm({});
            setGoogleSelectedPlace({});
            closeDialog();
          });
        }}
        render={(formikProps) => <NotAtHome {...formikProps} />}
      />
    )
};

NewNotAtHomeForm.propTypes = {
  addNotAtHome: Proptypes.func.isRequired,
  closeDialog: Proptypes.func.isRequired,
  common: Proptypes.objectOf(Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
    Proptypes.bool,
    Proptypes.object
  ])),
  setGoogleSelectedPlace: Proptypes.func.isRequired,
};

NewNotAtHomeForm.defaultProps = {
  common: {},
};

const mapStateToProps = state => {
	const { common } = state;
	return { common };
};

const mapDispatchToProps = {
	closeDialog: commonOperations.closeDialog,
	addNotAtHome: notAtHomeOperations.addNotAtHome,
	setGoogleSelectedPlace: commonOperations.setGoogleSelectedPlace,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewNotAtHomeForm);