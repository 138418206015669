import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withLocalize } from 'react-localize-redux';
import TranslateIcon from '@material-ui/icons/Translate';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '..';

import styles from './styles';
import { translate } from '../../utils';
import { languages } from '../../translations';

class LanguageButton extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    activeLanguage: PropTypes.object,
    topBarMobile: PropTypes.bool,
    setActiveLanguage: PropTypes.func.isRequired,
    size: PropTypes.string,
  }

  static defaultProps = {
    activeLanguage: { name: '' },
    topBarMobile: true,
    size: 'large'
  }

  state = {
    anchorEl: null,
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  renderLanguages = () => {
    const { activeLanguage } = this.props;
    return languages.map(language => {
      const { name, code } = language;
      return (
        <MenuItem
          key={code}
          selected={activeLanguage.code === code}
          onClick={() => this.languageSelect(code)}
        >
          {translate(name.toLocaleLowerCase())}
        </MenuItem>
      );
    });
  }

  languageSelect = code => {
    const { setActiveLanguage } = this.props;
    setActiveLanguage(code);
    this.handleClose();
    window.location.reload(false); 
  };

  render() {
    const {
      classes,
      topBarMobile,
      activeLanguage: { name },
      size,
     } = this.props;

    const { anchorEl } = this.state;
    
    const open = Boolean(anchorEl);


    return (
      <React.Fragment>
        <Button
          aria-owns={open ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="inherit"
          variant="text"
          className={topBarMobile ? classes.translateButtonMobile : classes.translateButton}
          size={size}
        >
          <TranslateIcon className={classes.languageIcon} />
          <div className={topBarMobile ? classes.headerIconLink : ''}>
            {translate(name.toLocaleLowerCase())}
          </div>
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}
        >
          {this.renderLanguages()}
        </Menu>
      </React.Fragment>
    )
  }
}

const enhance = compose(
  withLocalize,
  withStyles(styles),
);

export default enhance(LanguageButton);