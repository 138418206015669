import { layout, colors, padding } from "../../../styles";

export default {
  root: {
    flexGrow: 1,
    paddingTop: padding.sm
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: padding.md
  },
  chip: {
    minWidth: padding.xl,
    color: colors.white
  },
  button: {
    marginRight: padding.md
  },
  meta: {
    display: layout.fx,
    alignItems: layout.ct
  },
  updatedLabel: {
    paddingLeft: padding.md,
    color: colors.grey
  },
  headerLeft: {
    display: layout.fx,
    marginLeft: padding.md
  }
};