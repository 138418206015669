import { initializePlaces } from './googlePlaces';
import { googleMap } from './googleMap';
import { isToday, formatDate, fromNowDate, dateLocale, localeDateFormat } from './time';
import { translate, onLanguageChange, onChange } from './language';
import { loadScript, loadGoogleMapScript } from './sripts';
import users from '../screens/Login/users';
import { store } from '../state/store';

const isProduction = process.env.NODE_ENV === 'production';

const randomId = Math.random().toString(36).substring(7);

const formErrors = {
  string: 'No text allowed',
  number: 'No numbers allowed',
  required: translate('no_empty'),
}

const renderHelperText = (props = {}, value = '') => {
  return (props.touched[value] && props.values[value]) ? props.errors[value] : ''
};

const showError = (props = {}, value = '') => {
  return Boolean(
    props.touched[value] &&
    props.values[value] &&
    Boolean(props.errors[value]))
};

const FormHelpers = {
  renderHelperText,
  showError
}

const routes = ['service-schedule', 'not-at-home', 'my-not-at-homes'];

function AuthPermissions() {

  // const { store } = configureStore();

  this.check = user => {
    const { common } = store.getState();

    if (common.user && common.user.email) {
      const { email } = common.user;
      const title = email.substring(email.indexOf('_') + 1, email.indexOf('@'));
      return title === user;
    }
    
    return false;
  };

  this.isServant = () => this.check(users[1]);

  this.isPublisher = () => this.check(users[0]);
}

const Permissions = new AuthPermissions();

const shadeColor = (color, percent) => {
  let R = parseInt(color.substring(1,3),16);
  let G = parseInt(color.substring(3,5),16);
  let B = parseInt(color.substring(5,7),16);

  R = parseInt(R * (100 + percent) / 100, 10);
  G = parseInt(G * (100 + percent) / 100, 10);
  B = parseInt(B * (100 + percent) / 100, 10);

  R = (R<255)?R:255;  
  G = (G<255)?G:255;  
  B = (B<255)?B:255;  

  const RR = ((R.toString(16).length === 1)?`0${R.toString(16)}`:R.toString(16));
  const GG = ((G.toString(16).length === 1)?`0${G.toString(16)}`:G.toString(16));
  const BB = ((B.toString(16).length === 1)?`0${B.toString(16)}`:B.toString(16));

  return `#${RR}${GG}${BB}`;
}

const activeRoute = ({ location }) => {  
  const paths = location.pathname.substr(1).split('/');
  return paths.length <= 1 ? '' : paths[paths.length - 1];
}

const formatMetaName = congregationId => `000-${congregationId}-meta`;

const firebaseDate = data => {
  const { createdAt, time } = data;

  if (time) {
    return time.toDate();
  }

  if (createdAt.toDate) {
    return createdAt.toDate();
  }

  if (createdAt.createdAtTimestamp) {
    return createdAt.createdAtTimestamp
  }

  return null;
}

const isMobile = {
  Android() {
      return navigator.userAgent.match(/Android/i);
  },
  BlackBerry() {
      return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera() {
      return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows() {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any() {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  },
  pwa() {
    return ('standalone' in window.navigator) && window.navigator.standalone
  }
};

export {
  loadScript,
  googleMap,
  isProduction,
  isToday,
  initializePlaces,
  formErrors,
  randomId,
  loadGoogleMapScript,
  translate,
  onLanguageChange,
  formatDate,
  fromNowDate,
  dateLocale,
  localeDateFormat,
  onChange,
  routes,
  Permissions,
  shadeColor,
  activeRoute,
  FormHelpers,
  formatMetaName,
  firebaseDate,
  isMobile
};
