import * as actions from "./actions";
import * as commonActions from "../Common/operations";
import { ServiceSchedule } from "../../../services";
import { translate } from "../../../utils";
import { placePointsOnMap, googleMap } from "../../../utils/googleMap";

const { openNewScheduleDialog, closeNewScheduleDialog } = actions;

const deleteOldSchedules = (data) => {
  const today = new Date();
  today.setHours(0,0,0,0);
  const past = data.filter(schedule => {
    return schedule.date.toDate() < today;
  });

  const future = data.filter(schedule => {
    return schedule.date.toDate() >= today;
  });

  if (past.length) {
    ServiceSchedule.deleteAll(past);
  }

  return future;
}

const loadServiceSchedule = (callback = () => {}) => async (dispatch, getState) => {
  dispatch(actions.loadServiceSchedule());

  const { common: { congregationId } } = getState();

  ServiceSchedule.setCollectionPrefix(congregationId);

  try {
    const data = await ServiceSchedule.getAll();
    const futureDates = deleteOldSchedules(data);
    dispatch(actions.loadServiceScheduleComplete(futureDates));

     // Update map with new state
     const { serviceSchedule: { serviceSchedule } } = getState();
     placePointsOnMap(serviceSchedule, dispatch, commonActions);
     googleMap.setGeocoderCallback((adddress) => {
       dispatch(commonActions.setGoogleSelectedPlace(adddress));
       dispatch(commonActions.openDialog(adddress.formatted_address));
     });

     callback();
  } catch (error) {
    dispatch(actions.loadServiceScheduleError(error.message));
  }
};

const addServiceSchedule = (schedule, callback = () => {}) => async dispatch => {
  dispatch(actions.addServiceSchedule());

  let serviceSchedule = schedule;

  if (!serviceSchedule.location) {
      
    // newNotAtHome = await googleMap.addLocation(newNotAtHome);
    try {
      const googleRespose = await googleMap.serviceGetLocation(`${schedule.address}台中`);
      serviceSchedule = {
        ...serviceSchedule,
        address: googleRespose.name,
        location: {
          lat: googleRespose.geometry.location.lat(),
          lng: googleRespose.geometry.location.lng(),
        }
      }
    } catch (error) {
      console.log('Unable to retrieve location');
    }
  }  
  
  try {
    await ServiceSchedule.add(serviceSchedule);
    dispatch(loadServiceSchedule());
    // dispatch(actions.addServiceScheduleComplete(schedule));
    callback();
  } catch (error) {
    dispatch(actions.addServiceScheduleFail(error.message));
  }
};

const deleteServiceSchedule = (callback = () => {}) => async (dispatch, getState) => {
  const { common: { activeCard } } = getState();
  dispatch(actions.deleteServiceSchedule());

  const fail = message => {
    dispatch(actions.deleteServiceScheduleFail(message));
    dispatch(commonActions.toggleSnack(message));
  }

  if (!activeCard.id) {
    return fail('No active id');
  }

  const prevAdress = activeCard.address;

  try {
    await ServiceSchedule.permanentlyDeleteById(activeCard.id);
    dispatch(loadServiceSchedule(() => {
      dispatch(actions.deleteServiceScheduleComplete());
      dispatch(commonActions.toggleSnack(translate('sucessful_delete', { data: {
        address: `"${prevAdress}"`
      }})));
      callback();
    }));
  } catch (error) {
    fail(error.message);
    callback();
  }
};

export { loadServiceSchedule, openNewScheduleDialog, closeNewScheduleDialog, addServiceSchedule, deleteServiceSchedule };