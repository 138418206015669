/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { randomId, loadGoogleMapScript, googleMap, onLanguageChange, routes, activeRoute } from '../../utils'; 
import { Button } from '..'
import { commonOperations } from '../../state/ducks/Common';
import { notAtHomeOperations } from '../../state/ducks/NotAtHome';

import styles, { title } from './styles';

class GoogleMap extends Component {
  static propTypes = {
    setGoogleMapReference: PropTypes.func.isRequired,
    refetchData: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    activeLanguage: PropTypes.object,
    location: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    activeCard: PropTypes.object.isRequired,
    refresh: PropTypes.bool,
    history: PropTypes.object.isRequired,
    checkingOutTerritories: PropTypes.bool,
    selectHome: PropTypes.func.isRequired,
    selectedHomes: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    activeLanguage: {},
    refresh: false,
    checkingOutTerritories: false,
    selectedHomes: []
  }

  constructor(props) {
    super(props);
    this.apiKey = 'AIzaSyAwE54uIT3Gg4VD-PMybTwHmmANTl3UwVQ';
    this.prefix = randomId;
  }

  state = {
    initialMapLoaded: false,
  }

  componentDidMount() {
    const { refetchData, location } = this.props;
    
    this.loadMap(() => {
      this.setState({ initialMapLoaded: true });
      refetchData(location)
    });
  }

  componentDidUpdate(prevProps) {
    const { initialMapLoaded } = this.state;
    const { refetchData, location } = this.props;
    
    // Prevents double loading of map bug
    if (initialMapLoaded) {
      onLanguageChange(() => {
        this.loadMap(() => refetchData(location));
      })(prevProps, this.props)
    }
  }

  loadMap = (callback = () => {}) => {
    const { activeLanguage, setGoogleMapReference } = this.props;
    loadGoogleMapScript(activeLanguage.code, ['places'], this.apiKey).then(() => {
      googleMap.init(map => {
        setGoogleMapReference({
          google: window.google,
          map,
        });
        callback();
      })
    });
  }

  renderDirectionsButton = () => {
    const { translate, classes, activeCard } = this.props;

    const isActive = Object.keys(activeCard).length;

    return (
      <div style={isActive ? { display: 'block' } : { display: 'none' }}>
        {/* <br /> */}
        <Button size="small" className={classes.button} id="directions-button">
          {translate('directions')}
        </Button>
      </div>
    )
  }

  renderAddNAHButton = () => {
    const {
      translate,
      classes,
      activeCard,
      selectHome,
      checkingOutTerritories,
      history,
      refresh,
      selectedHomes
    } = this.props;

    
    if (activeRoute(history) !== routes[1] || refresh) {
      return null;
    }

    const selected = selectedHomes.indexOf(activeCard.id) !== -1;

    const isActive = Object.keys(activeCard).length;

    return (
      <div style={isActive ? { display: 'block' } : { display: 'none' }}>
        <Button
          loading={checkingOutTerritories}
          onClick={() => selectHome(activeCard)}
          size="small"
          variant="outlined"
          className={classnames(classes.button, classes.addButton)}
          color={selected ? 'secondary' : 'primary'}
        >
          {selected && translate('checkout.uncheckout')}
          {!selected && translate('checkout.checkout')}
        </Button>
      </div>
    )
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className="map" id="google-map" />
        <div id="infowindow-content">
          <div id="place-address" className={classes.address} />

          <img className={classes.icon} alt="icon" src="" width="16" height="16" id="place-icon" />
          <span id="place-name" className="title" style={title} />
          <div className={classes.actionsContainer}>
            {this.renderDirectionsButton()}
            {this.renderAddNAHButton()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
	const { notAtHome, common  } = state;
	return { ...notAtHome, ...common };
};

const mapDispatchToProps = {
  setGoogleMapReference: commonOperations.setGoogleMapReference,
  setActiveCard: commonOperations.setActiveCard,
  refetchData: commonOperations.refetchData,
  showLeftSide: commonOperations.showLeftSide,
  selectHome: notAtHomeOperations.selectHome,
  checkoutNotAtHomes: notAtHomeOperations.checkoutNotAtHomes,
}

const enhance = compose(
  connect(
      mapStateToProps,
      mapDispatchToProps,
  ),
  withRouter,
  withLocalize,
  withStyles(styles)
);

const Map = enhance(GoogleMap);

export { Map };



