import _ from 'lodash';
import { Auth } from '../../../services';
import * as actions from './actions';
import { googleMap, isMobile } from '../../../utils';
// import * as notAtHomeOperations from '../NotAtHome/operations';
// import * as serviceScheduleOperations from '../ServiceSchedule/operations';
// import { routes } from '../../../utils';

const {
  openDialog,
  closeDialog,
  setCongregationId,
  setGoogleMapReference,
  setGoogleSelectedPlace,
  showLeftSide,
  toggleSnack,
  toggleConfirm,
  setActiveCard,
  setUniqueUserId
} = actions;

const refetchData = (location, callback = () => {}) => async dispatch => {
  // const paths = location.pathname.split('/');
  // const activeRoute = paths[paths.length - 1];

  // console.log('ACR', activeRoute);

  // if (activeRoute === routes[0] || activeRoute === '') {
  //   dispatch(serviceScheduleOperations.loadServiceSchedule(callback))
  // } else if (activeRoute === routes[1]) {
  //   dispatch(notAtHomeOperations.loadNotAtHome(callback))
  // } else {
  //   const message = `Calling refresh data on neither ${routes[0]} nor  ${routes[1]}`;
  //   dispatch(toggleSnack(message));
  // }
};

const authenticate = callback => async dispatch => {
  Auth.onAuthStateChanged((user) => {    
    dispatch(actions.setUser(user));
    callback();
  });
};

const locateCardOnMap = (e, card) => async dispatch =>{
  e.stopPropagation();

  const {
    address,
    location,
    updatedAt,
    id,
    createdAt,
    notes,
  } = card;
  
  if (!_.isEmpty(location)) {
    googleMap.markerFocus(id)
    dispatch(actions.showLeftSide(false));
    dispatch(actions.setActiveCard({
      id,
      address,
      updatedAt,
      createdAt,
      notes
    }));
  } else {
    dispatch(actions.setActiveCard({}))
    const googleUrl = 'https://www.google.com/maps/place/';
    // Fixes bug where when opened on mobile, opens a blank tab in Chrome
    let target = '_self';
    
    if (isMobile.pwa()) {
      target = '_top'
    } else if (isMobile.any()) {
      target = '_self'
    } else {
      target = '_blank'
    }

    const w = window.open(`${googleUrl}${encodeURIComponent(address)}`, target);
    w.focus();
  }
}

const login = (email, password, successCallback, errorCallback) => async () => {
  try {
    await Auth.login(email, password);
    successCallback();
  } catch (error) {
    errorCallback(error);
  }
}

export {
  openDialog,
  closeDialog,
  setCongregationId,
  setGoogleMapReference,
  setGoogleSelectedPlace,
  showLeftSide,
  refetchData,
  toggleSnack,
  toggleConfirm,
  setActiveCard,
  authenticate,
  login,
  setUniqueUserId,
  locateCardOnMap
};