import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Center } from '..';

const Loader = () => (
  <Center>
    <CircularProgress />
  </Center>
);

export { Loader };