import { CommonModel } from './common';

const NOT_AT_HOME_COLLECTION = 'notAtHome';

const NOT_AT_HOME_SCHEMA = {
  address: '',
  number: null,
  notes: '',
  postedBy: ''
};

class NotAtHomeService extends CommonModel {
  constructor() {
    super(NOT_AT_HOME_COLLECTION, NOT_AT_HOME_SCHEMA);
  }

  add(serviceSchedule) {
    return new Promise(async (resolve, reject) => {
      try {
        const payload = await this.create(serviceSchedule);
        resolve(payload);
      } catch (error) {
        reject(new Error(error.message));
      }
    });
  }

  update(serviceScheduleId, serviceSchedule) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.updateById(serviceScheduleId, serviceSchedule);
        resolve();
      } catch (error) {
        reject(new Error(error.message));
      }
    });
  }

  delete(serviceScheduleId) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.deleteById(serviceScheduleId);
        resolve();
      } catch (error) {
        reject(new Error(error.message));
      }
    });
  }

  getOne(serviceScheduleId) {
    return new Promise(async (resolve, reject) => {
      try {
        const serviceSchedule = await this.findById(serviceScheduleId);
        resolve(serviceSchedule);
      } catch (error) {
        reject(new Error(error.message));
      }
    });
  }

  getAll() {
    return new Promise(async (resolve, reject) => {
      let notAtHomes = [];
      try {
        const querySnapshot = await this.firestore
          .collection(this.collection)
          .orderBy('createdAt', this.verbage.desc)
          .get();

        querySnapshot.forEach(doc => {
          const serviceSchedule = {
            id: doc.id,
            ref: doc.ref,
            ...doc.data()
          };

          notAtHomes = [...notAtHomes, serviceSchedule];
        });

        resolve(notAtHomes);
      } catch (error) {
        reject(new Error(error.message));
      }
    });
  }
}

const NotAtHome = new NotAtHomeService();
export { NotAtHome };
