import * as actions from './actions';
import * as commonActions from '../Common/operations';
import * as notAtHomeActions from '../NotAtHome/operations';
import { NotAtHome } from '../../../services';
import { placePointsOnMap } from '../../../utils/googleMap';
import { translate } from '../../../utils';

const { loadMyNotAtHomesComplete } = actions;

const loadMyNotAtHomes = (callback = () => {}) => async (dispatch, getState) => {
  const { common: { congregationId } } = getState();

  NotAtHome.setCollectionPrefix(congregationId);

  // Update map with new state
  const { myNotAtHomes: { myNotAtHomes } } = getState();
  placePointsOnMap(myNotAtHomes, dispatch, commonActions);

  callback();
};

const deleteMyNotAtHome = (callback = () => {}) => async (dispatch, getState) => {
  const { common: { activeCard } } = getState();

  if (!activeCard.id) {
    return dispatch(commonActions.toggleSnack('No active card selected'));
  }

  const prevAdress = activeCard.address;

  dispatch(actions.deleteMyNotAtHome(activeCard.id));
  dispatch(commonActions.toggleSnack(translate('sucessful_delete', { data: {
    address: `"${prevAdress}"`
  }})));

  const { myNotAtHomes: { myNotAtHomes } } = getState();
  placePointsOnMap(myNotAtHomes, dispatch, commonActions);

  callback();
};

const returnMyNotAtHome = (callback = () => {}) => async (dispatch, getState) => {
  const { common: { activeCard } } = getState();

  const prevAdress = activeCard.address;

  if (!activeCard.id) {
    return dispatch(commonActions.toggleSnack('No active card selected'));
  }

  dispatch(notAtHomeActions.addNotAtHome({
    address: activeCard.address,
    location: activeCard.location,
    notes: activeCard.notes,
  }, () => {
    dispatch(actions.deleteMyNotAtHome(activeCard.id));
    dispatch(commonActions.toggleSnack(translate('sucessful_delete', { data: {
      address: `"${prevAdress}"`
    }})));

    const { myNotAtHomes: { myNotAtHomes } } = getState();
    placePointsOnMap(myNotAtHomes, dispatch, commonActions);

    callback();
  }))
};


export { loadMyNotAtHomes, loadMyNotAtHomesComplete, deleteMyNotAtHome, returnMyNotAtHome };