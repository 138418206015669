import * as types from './types';

const loadNotAtHome = () => ({
	type: types.LOAD_NOT_AT_HOME,
});

const loadNotAtHomeComplete = NotAtHomes => ({
	type: types.LOAD_NOT_AT_HOME_COMPLETE,
	payload: NotAtHomes,
});

const loadNotAtHomeError = error => ({
	type: types.LOAD_NOT_AT_HOME_FAIL,
	payload: error,
});

const addNotAtHome = () => ({
	type: types.ADD_NOT_AT_HOME,
});

const addNotAtHomeComplete = schedule => ({
	type: types.ADD_NOT_AT_HOME_COMPLETE,
	payload: schedule,
});

const addNotAtHomeFail = error => ({
	type: types.ADD_NOT_AT_HOME_FAIL,
	payload: error,
});

const deleteNotAtHome = () => ({
	type: types.DELETE_NOT_AT_HOME,
});

const deleteNotAtHomeComplete = id => ({
	type: types.DELETE_NOT_AT_HOME_COMPLETE,
	payload: id,
});

const deleteNotAtHomeFail = error => ({
	type: types.DELETE_NOT_AT_HOME_FAIL,
	payload: error,
});

const setSelectedHomes = home => ({
	type: types.SET_SELECTED_HOMES,
	payload: home,
});

const selectHome = ({ id }) => ({
	type: types.ADD_SELECTED_HOME,
	payload: id,
});

const setActiveCard = card => ({
	type: types.SET_ACTIVE_CARD,
	payload: card,
});

const setRefresh = refresh => ({
	type: types.SET_REFRESH,
	payload: refresh,
});

const checkingOutTerritories = load => ({
	type: types.CHECK_OUT_TERRITORY,
	payload: load,
});

export {
	loadNotAtHome,
	loadNotAtHomeComplete,
	loadNotAtHomeError,
	addNotAtHome,
	addNotAtHomeComplete,
	addNotAtHomeFail,
	deleteNotAtHome,
	deleteNotAtHomeComplete,
	deleteNotAtHomeFail,
	selectHome,
	setSelectedHomes,
	setActiveCard,
	setRefresh,
	checkingOutTerritories
};
