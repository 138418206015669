import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Button, ConfirmationDialog } from '../../../components';
import { notAtHomeOperations } from '../../../state/ducks/NotAtHome';
import { commonOperations } from '../../../state/ducks/Common';
import styles from './styles';

import FilterButton from './FilterButton';
import { formatDate, Permissions, firebaseDate } from '../../../utils';
import { colors } from '../../../styles';

const noLastUpdated = true;

class FilterBar extends Component {

  state = {
    showDeleteAllDialog: false,
    loadingDeletion: false,
  }

  lastUpdated = (a) => {
    const lastDate = new Date(Math.max.apply(null, a.map(e => new Date(firebaseDate(e)))));
    return formatDate(lastDate);
  }

  determineColor = () => {
    const { data } = this.props; 
    const count = data.length;

    const alpha = 0.2;

    const red = 'rgba(229, 57, 53, 1.0)';
    const yellow = 'rgba(255, 179, 0, 1.0)';
    const orange = 'rgba(251, 140, 0, 1.0)';
    const green = 'rgba(67, 160, 71, 1.0)';

    let pallete = '';

    if (count <= 50) {
      pallete = green;
    } else if (count <= 60) {
      pallete = yellow
    } else if (count > 60 && count <= 80) {
      pallete = orange;
    } else {
      pallete = red;
    }

    return {
      color: pallete,
      backgroundColor: pallete.replace('1.0', alpha)
    }
  }

  renderLastUpdated = () => {
    const {
      classes,
      data,
      translate,
    } = this.props; 

    if (noLastUpdated) {
      return null;
    }

    return (
      <Typography className={classes.updatedLabel} variant="body2">
        {translate('filter.last_update', { date: this.lastUpdated(data) })}
      </Typography>
    )
  }

  renderDeleteAllButton = () => {
    const { translate, data, classes, showDelete } = this.props; 

    if (Permissions.isServant() && data.length && showDelete) {
      return (
        <Button
          size="small"
          style={{ backgroundColor: colors.red }}
          onClick={this.handleOpen}
          className={classes.button}
        >
          {translate('filter.delete_all')}
        </Button>
      )
    }
    return null;
  }

  handleClose = () => {
    this.setState({ showDeleteAllDialog: false })
  }

  handleOpen = () => {
    this.setState({ showDeleteAllDialog: true })
  }

  renderDeleteAllDialog = () => {
    const { showDeleteAllDialog, loadingDeletion } = this.state;
    const { translate, data } = this.props;

    return (
      <ConfirmationDialog
        title={translate('are_you_sure')}
        message={translate(
          'want_to_delete',
          { address: translate('filter.all_territories', { count: data.length }) }
        )}
        open={showDeleteAllDialog}
        handleClose={this.handleClose}
        onConfirm={this.deleteAllNotAtHomes}
        loading={loadingDeletion}
      />
    )
  }

  deleteAllNotAtHomes = () => {
    const { deleteAllNotAtHomes } = this.props;    
    if (!Permissions.isServant()) {
      return;
    }

    deleteAllNotAtHomes(
      () => this.setState({ loadingDeletion: true }),
      () => this.setState({ loadingDeletion: false })
    )
  }

  render() {
    const {
      classes,
      data,
      translate,
      loadNotAtHomeComplete,
      toggleSnack,
    } = this.props;  
    
    const { color, backgroundColor } = this.determineColor();
  
    const count = data.length;

    return (
      <div className={classes.root}>
        {this.renderDeleteAllDialog()}
        <Toolbar disableGutters>
          <div className={classes.grow}>
            {this.renderLastUpdated()}
            <div className={classes.headerLeft}>
              {/* {this.renderDeleteAllButton()} */}
              <div className={classes.meta}>
                <Button
                  size="small"
                  style={{ color, backgroundColor }}
                  disabled
                >
                  {translate('filter.homes', { count })}
                </Button>
              </div>
            </div>
          </div>
          <FilterButton
            data={data}
            updateData={loadNotAtHomeComplete}
            top={translate('filter.homes')}
            translate={translate}
            toggleSnack={toggleSnack}
          />
        </Toolbar>
      </div>
    );
  }
}

FilterBar.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array,
  translate: PropTypes.func.isRequired,
  loadNotAtHomeComplete: PropTypes.func.isRequired,
  toggleSnack: PropTypes.func.isRequired,
  deleteAllNotAtHomes: PropTypes.func.isRequired,
  showDelete: PropTypes.bool,
};

FilterBar.defaultProps = {
  data: [],
  showDelete: false
};

const mapStateToProps = ({ notAtHome }) => notAtHome;

const mapDispatchToProps = {
  loadNotAtHomeComplete: notAtHomeOperations.loadNotAtHomeComplete,
  deleteAllNotAtHomes: notAtHomeOperations.deleteAllNotAtHomes,
  toggleSnack: commonOperations.toggleSnack,
};

const enhance = compose(
  withLocalize,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
);

export default enhance(FilterBar);