/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { initializePlaces, translate as _translate } from '../../../utils'
import { commonOperations } from '../../../state/ducks/Common';
import { Dialog, Button } from '../../../components';

class NewNotAtHomeDialog extends Component {
  static propTypes = {
    dialogOpen: PropTypes.bool.isRequired,
    setGoogleSelectedPlace: PropTypes.func.isRequired,
    closeDialog: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }

  id = 'address';

  componentWillReceiveProps(nextProps) {
    const { dialogOpen } = this.props;
  
    if (nextProps.dialogOpen !== dialogOpen) {
      if (nextProps.dialogOpen) {
        setTimeout(this.initGoogleAutocompletePlaces, 1000);
      }
    }
  }

  initGoogleAutocompletePlaces = () => {
    const { setFieldValue } = this.props;
    initializePlaces(this.props, this.id, place => {
      setFieldValue(this.id, place.name)
    });
  }

  handleDialogClose = () => {
    const { setGoogleSelectedPlace, closeDialog } = this.props;
    setGoogleSelectedPlace({});
    closeDialog();
  }

  render = () => {
    const {
      dialogOpen,
      handleChange,
      handleBlur,
      values,
      setFieldTouched,
      touched,
      errors,
      handleSubmit,
      isValid,
      isSubmitting
    } = this.props;

    const onChange = (name, e) => {
      e.persist();
      handleChange(e);
      setFieldTouched(name, true, false);
    };

    const helperText = path => (touched[path] && values[path]) ? errors[path] : '';
    const showError = path => Boolean(touched[path] && values[path] && Boolean(errors[path]));
    
    return (
      <form onSubmit={handleSubmit}>
        <Dialog
          open={dialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">{_translate('not_at_home_dialogue.new_not_at_home')}</DialogTitle>
          <DialogContent>
            <Translate>
              {({ translate }) => (
                <React.Fragment>
                  <TextField
                    autoFocus
                    helperText={helperText('address')}
                    error={showError('address')}
                    onChange={onChange.bind(null, this.id)}
                    onBlur={handleBlur}
                    value={values.address}
                    margin="dense"
                    id="address"
                    label={translate('address')}
                    fullWidth
                    placeholder={translate('enter_location')}
                  />
                  <TextField
                    helperText={helperText('notes')}
                    error={showError('notes')}
                    onChange={onChange.bind(null, this.id)}
                    onBlur={handleBlur}
                    value={values.notes}
                    margin="dense"
                    id="notes"
                    label={translate('notes')}
                    fullWidth
                    multiline
                    placeholder={translate('notes')}
                  />
                </React.Fragment>
              )}
            </Translate>
            {/* <TextField
              helperText={touched.number ? errors.number : ''}
              error={touched.number && Boolean(errors.number)}
              onChange={onChange.bind(null, 'number')}
              onBlur={handleBlur}
              value={values.number}
              margin="dense"
              id="number"
              label="House Number"
              multiline
              placeholder="House Number"
            /> */}
            {/* <TextField
              id="notes"
              label="Notes"
              multiline
              rowsMax="4"
              value={values.notes}
              helperText={touched.notes ? errors.notes : ''}
              error={touched.notes && Boolean(errors.notes)}
              onChange={onChange.bind(null, 'notes')}
              onBlur={handleBlur}
              margin="normal"
              fullWidth
            /> */}
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={this.handleDialogClose}
            >
              {_translate('cancel')}
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
            >
              {_translate('create')}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    );
  }
}

const mapStateToProps = state => {
	const { notAtHome, common } = state;
	return { ...notAtHome, ...common };
};

const mapDispatchToProps = {
  closeDialog: commonOperations.closeDialog,
  setGoogleSelectedPlace: commonOperations.setGoogleSelectedPlace,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewNotAtHomeDialog);