import React from 'react';
import PropTypes from 'prop-types';
import List from './components/List';

const Content = ({ children, ...rest }) => {
  return (
    <React.Fragment>
      {children}
      <List {...rest} />
    </React.Fragment>
  )
};

Content.propTypes = {
  children: PropTypes.node
}

Content.defaultProps = {
  children: null
}

export default Content;