export const DIALOG_OPEN = 'app/common/dialog_open';
export const DIALOG_CLOSE = 'app/common/dialog_close';

export const SET_GOOGLE_MAP_REFERENCE = 'app/common/set_google_map_reference';
export const SET_GOOGLE_SELECTED_PLACE = 'app/common/set_google_selected_place';

export const RESPONSIVE_SHOW_LEFT_SIDE = 'app/common/responsive_show_left_side';

export const TOGGLE_SNACK = 'app/common/toggle_snack';
export const TOGGLE_CONFIRM = 'app/common/toggle_confirm';

export const SET_ACTIVE_CARD = 'app/common/set_active_card';
export const SET_CONGREGATION_ID = 'app/common/set_congregation_id';
export const SET_AUTH_USER = 'app/common/set_auth_user';
export const SET_UNIQUE_USER_ID = 'app/common/set_unique_user_id';
