export const LOAD_SERVICE_SCHEDULE = 'app/service_schedule/load_service_schedule';
export const LOAD_SERVICE_SCHEDULE_COMPLETE = 'app/service_schedule/load_service_schedule_complete';
export const LOAD_SERVICE_SCHEDULE_FAIL = 'app/service_schedule/load_service_schedule_fail';

export const ADD_SERVICE_SCHEDULE = 'app/service_schedule/add_service_schedule';
export const ADD_SERVICE_SCHEDULE_COMPLETE = 'app/service_schedule/add_service_schedule_complete';
export const ADD_SERVICE_SCHEDULE_FAIL = 'app/service_schedule/add_service_schedule_fail';

export const DELETE_SERVICE_SCHEDULE = 'app/service_schedule/delete_nah';
export const DELETE_SERVICE_SCHEDULE_COMPLETE = 'app/service_schedule/delete_nah_complete';
export const DELETE_SERVICE_SCHEDULE_FAIL = 'app/service_schedule/delete_nah_fail';

export const NEW_SCHEDULE_DIALOG_OPEN = 'app/service_schedule/new_schedule_dialog_open';
export const NEW_SCHEDULE_DIALOG_CLOSE = 'app/service_schedule/new_schedule_dialog_close';

export const SET_GOOGLE_MAP_REFERENCE = 'app/service_schedule/set_google_map_reference';
export const SET_GOOGLE_SELECTED_PLACE = 'app/service_schedule/set_google_selected_place';
