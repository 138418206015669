import React from 'react';
import PropTypes from 'prop-types';
import LineEllipsis from 'react-lines-ellipsis'
import styles from './styles';

const Text = props => {
  const { children, style, ...rest } = props;
  return (
    <LineEllipsis
      style={{ ...styles, ...style }}
      text={children}
      maxLine="2"
      component="p"
      basedOn="letters"
      {...rest}
    />
  )
}

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  style: PropTypes.object,
}

Text.defaultProps = {
  children: '',
  style: {},
}

export { Text };