import { layout, padding, colors } from "../../styles";

export default theme => ({
  root: {
    display: layout.fx,
  },
  title: {
    paddingBottom: 0,
  },
  actions: {
    justifyContent: layout.sb,
    paddingLeft: padding.sm,
    paddingRight: padding.sm
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
    flexDirection: layout.rw
  },
  errorSnack: {
    margin: '10px 12px'
  },
  sample: { color: colors.red }
});