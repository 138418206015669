import { CommonModel } from './common';

const USER_COLLECTION = 'user';

const USER_SCHEMA = {
  name: ''
};

class AuthService extends CommonModel {
  constructor() {
    super(USER_COLLECTION, USER_SCHEMA);
  }

  onAuthStateChanged(callback) {
    this.auth.onAuthStateChanged(callback)
  }

  login(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.auth.signOut();
  }
}

const Auth = new AuthService();
export { Auth };
