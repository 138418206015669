import moment from 'moment';
import * as actions from './actions';
import * as commonActions from '../Common/operations';
import * as myNotAtHomesActions from '../MyNotAtHomes/operations';
import { NotAtHome, Meta } from '../../../services';
import { googleMap, translate } from '../../../utils';
import { placePointsOnMap } from '../../../utils/googleMap';

const DAYS_TILL_EXPIRE = 60;

const {
  selectHome,
  setActiveCard,
  loadNotAtHomeComplete,
  setRefresh,
  loadNotAtHomeError
} = actions;

const deleteOldNotAtHomes = (data) => {
  const today = moment();

  const past = data.filter(schedule => {
    const dateToCheck = moment(schedule.createdAt.toDate());
    const pastDays = today.diff(dateToCheck, 'd');
    return (pastDays + 1) > DAYS_TILL_EXPIRE;
  });

  if (past.length) {    
    NotAtHome.deleteAll(past);
  }
}

const subscribeToMeta = (getState, dispatch) => {
  const { common: { congregationId, user } } = getState();

  let firstLoadLoaded = false;

  Meta.subscribe(congregationId, data => {
    const { meta = {} } = data;

    if (firstLoadLoaded && meta.lastUpdateBy && meta.lastUpdateBy !== user.userId) {
      dispatch(actions.setRefresh(true));
    }

    if (!firstLoadLoaded) {
      firstLoadLoaded = true;
    }
  });
}

const loadNotAtHome = (callback = () => {}) => async (dispatch, getState) => {
  dispatch(actions.loadNotAtHome());

  const { common: { congregationId } } = getState();

  NotAtHome.setCollectionPrefix(congregationId);

  subscribeToMeta(getState, dispatch);

  try {
    const data = await NotAtHome.getAll();

    deleteOldNotAtHomes(data);

    dispatch(actions.setRefresh(false));

    dispatch(actions.loadNotAtHomeComplete(data));

    // Update map with new state
    const { notAtHome: { notAtHome } } = getState();
    placePointsOnMap(notAtHome, dispatch, commonActions);

    callback();
  } catch (error) {
    dispatch(actions.loadNotAtHomeError(error.message));
  }
};

const addNotAtHome = (notAtHome, callback = () => {}) => async (dispatch, getState) => {
  const { common: { user, congregationId } } = getState();

  let newNotAtHome = {
    ...notAtHome,
    postedBy: user.userId,
  }

  dispatch(actions.addNotAtHome());

  // check if already exists

  try {
    if (!newNotAtHome.location) {
      
      // newNotAtHome = await googleMap.addLocation(newNotAtHome);
      try {
        const googleRespose = await googleMap.serviceGetLocation(newNotAtHome.address);
        newNotAtHome = {
          ...newNotAtHome,
          address: googleRespose.name,
          location: {
            lat: googleRespose.geometry.location.lat(),
            lng: googleRespose.geometry.location.lng(),
          }
        }
      } catch (error) {
        console.log('Unable to retrieve location');
      }
    }        

    const nah = await NotAtHome.add(newNotAtHome);
    Meta.create(congregationId, user.userId);

    // Mock what we are sending to firebase
    const payload = NotAtHome.createFirebasePayload({
      ...nah,
      createdAt: { toDate: () => Date.now() },
      updatedAt: { toDate: () => Date.now() },
    });

    dispatch(actions.addNotAtHomeComplete(payload));

    const { notAtHome: { notAtHome } } = getState();
    placePointsOnMap(notAtHome, dispatch, commonActions);

    callback();
  } catch (error) {
    dispatch(actions.addNotAtHomeFail(error.message));
  }
};

const deleteNotAtHome = (callback = () => {}) => async (dispatch, getState) => {
  const { common: { activeCard, congregationId, user } } = getState();
  dispatch(actions.deleteNotAtHome());

  const fail = message => {
    dispatch(actions.deleteNotAtHomeFail(message));
    dispatch(commonActions.toggleSnack(message));
  }

  if (!activeCard.id) {
    return fail('No active id');
  }

  const prevAdress = activeCard.address;

  try {
    await NotAtHome.permanentlyDeleteById(activeCard.id);
    Meta.create(congregationId, user.userId);

    dispatch(actions.deleteNotAtHomeComplete(activeCard.id));
    dispatch(commonActions.toggleSnack(translate('sucessful_delete', { data: {
      address: `"${prevAdress}"`
    }})));

    const { notAtHome: { notAtHome } } = getState();
    placePointsOnMap(notAtHome, dispatch, commonActions);

    callback();
  } catch (error) {
    fail(error.message);
    callback();
  }
};

const checkoutNotAtHomes = (callback = () => {}) => async (dispatch, getState) => {
  const { notAtHome: { selectedHomes, notAtHome }, common: { user, congregationId } } = getState();

  dispatch(actions.checkingOutTerritories(true));

  try {
    await NotAtHome.permanentlyDeleteCollection(selectedHomes);

    Meta.create(congregationId, user.userId);

    // UI Update
    const availableToCheckout = notAtHome.filter(home => selectedHomes.indexOf(home.id) === -1);
    const unavailableToCheckout = notAtHome.filter(home => selectedHomes.indexOf(home.id) !== -1);
     

    dispatch(actions.loadNotAtHomeComplete(availableToCheckout));

    const persistedDateCheckout = unavailableToCheckout.map(d => {
      const { createdAt, updatedAt } = d;
      const createdAtTimestamp = createdAt.toDate();
      const updatedAtTimestamp = updatedAt.toDate();
      return ({
        ...d,
        ref: null,
        createdAt: { ...createdAt, createdAtTimestamp },
        updatedAt: { ...updatedAt, updatedAtTimestamp },
      })
    });
    
    // Update my not at homes
    dispatch(myNotAtHomesActions.loadMyNotAtHomesComplete(persistedDateCheckout));

    dispatch(actions.setSelectedHomes([]));

    const state = getState();
    placePointsOnMap(state.notAtHome.notAtHome, dispatch, commonActions);

    dispatch(commonActions.toggleSnack(translate('checkout_success')));
    dispatch(actions.checkingOutTerritories(false));
    callback();
  } catch (error) {
    dispatch(commonActions.toggleSnack(translate('checkout_fail')));
    dispatch(actions.checkingOutTerritories(false));
  }
};

const deleteAllNotAtHomes = (loading = () => {}, complete = () => {}) => async (dispatch, getState) => {
  loading();
  
  const { notAtHome: { notAtHome }, common: { congregationId, user } } = getState();
  const allIds = notAtHome.map(({ id }) => id);

  try {
    await NotAtHome.permanentlyDeleteCollection(allIds);
    Meta.create(congregationId, user.userId);

    dispatch(actions.loadNotAtHomeComplete([]));
    placePointsOnMap([], dispatch, commonActions);
    complete();
  } catch (error) {
    complete(error.message);
  }
};

export {
  loadNotAtHome,
  addNotAtHome,
  selectHome,
  deleteNotAtHome,
  setActiveCard,
  loadNotAtHomeComplete,
  checkoutNotAtHomes,
  deleteAllNotAtHomes,
  setRefresh,
  loadNotAtHomeError
};