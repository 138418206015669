import { combineReducers } from 'redux';
import * as types from './types';

/* State shape
{
	dialogOpen: false,
	dialogDefaultAddress: '',
	congregationId: '',

	googleMapReference: null,
	googleSelectedPlace: {},
	responsiveShowLeftSide: null,

	snack: '',
	confirm: false,
	activeCard: {},
	user: null,
}
*/

const dialogOpen = (state = false, action) => {
	switch (action.type) {
		case types.DIALOG_OPEN:
			return true;
		case types.DIALOG_CLOSE:
			return false;
		default:
			return state;
	}
};

const dialogDefaultAddress = (state = '', action) => {
	switch (action.type) {
		case types.DIALOG_OPEN:
			return action.payload || '';
		case types.DIALOG_CLOSE:
			return '';
		default:
			return state;
	}
};

const congregationId = (state = '', action) => {
	switch (action.type) {
		case types.SET_CONGREGATION_ID:
			return action.payload;
		default:
			return state;
	}
};

const googleReference = (state = null, action) => {
	switch (action.type) {
		case types.SET_GOOGLE_MAP_REFERENCE:
			return action.payload.google;
		default:
			return state;
	}
};

const googleMapReference = (state = null, action) => {
	switch (action.type) {
		case types.SET_GOOGLE_MAP_REFERENCE:
			return action.payload.map;
		default:
			return state;
	}
};

const googleSelectedPlace = (state = null, action) => {
	switch (action.type) {
		case types.SET_GOOGLE_SELECTED_PLACE:
			return action.payload;
		default:
			return state;
	}
};

const responsiveShowLeftSide = (state = null, action) => {
	switch (action.type) {
		case types.RESPONSIVE_SHOW_LEFT_SIDE:
			return action.payload;
		default:
			return state;
	}
};

const snack = (state = '', action) => {
	switch (action.type) {
		case types.TOGGLE_SNACK:
			return action.payload;
		default:
			return state;
	}
};

const confirm = (state = false, action) => {
	switch (action.type) {
		case types.TOGGLE_CONFIRM:
			return action.payload;
		default:
			return state;
	}
};

const activeCard = (state = {}, action) => {
	switch (action.type) {
		case types.SET_ACTIVE_CARD:
			return action.payload;
		default:
			return state;
	}
};

const user = (state = null, action) => {
	switch (action.type) {
		case types.SET_AUTH_USER:
			return action.payload;
		case types.SET_UNIQUE_USER_ID:
			return { ...state, userId: action.payload };
		default:
			return state;
	}
};

const reducer = combineReducers({
	dialogOpen,
	dialogDefaultAddress,
	congregationId,
	googleReference,
	googleMapReference,
	googleSelectedPlace,
	responsiveShowLeftSide,
	snack,
	confirm,
	activeCard,
	user
});

export default reducer;
