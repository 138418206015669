import 'moment/locale/zh-tw';
import 'moment/locale/en-ca';
import moment from 'moment';

const isToday = date => {
  const today = new Date();
  const someDate = new Date(date);
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

const formatDate = date => {
  // return moment(date).format('MMMM Do YYYY, h:mm a')
  return localeDateFormat(date, null, { code: 'zh-TW' }).timestamp
}

const fromNowDate = date => {
  return moment(date).fromNow();
}

const dateLocale = moment.locale;

const localeDateFormat = (dateStamp, timeStamp = null, { code }) => {
  const momentTime =  moment(dateStamp.toDate ? dateStamp.toDate() : dateStamp);

  const dateFormat = code === 'zh-TW' ? 'YYYY年MM月DD日' : 'MMMM Do YYYY';
  const timeFormat = code === 'zh-TW' ? 'HH:mm' : 'h:mm a';
  const dayOfWeek = moment().weekday(momentTime.weekday()).format('dd')
  
  const date = momentTime.format(dateFormat);
  const time = timeStamp ? moment(timeStamp.toDate()).format(timeFormat) : momentTime.format(timeFormat);
  const timestamp = `${date}(${dayOfWeek}) ${time}`;

  return ({
    date,
    time,
    dayOfWeek,
    timestamp
  })
}

export { isToday, formatDate, fromNowDate, dateLocale, localeDateFormat };