import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MDButton from '@material-ui/core/Button';

import styles from './styles';

const TMButton = props => {
  const { children, className, classes, loading, ...rest } = props;
  return (
    <MDButton
      className={classnames(classes.button, className)}
      size="large"
      variant="contained"
      color="primary"
      disabled={loading}
      {...rest}
    >
      {loading && <CircularProgress color="inherit" size={25} />}
      {!loading && children}
    </MDButton>
  )
}

TMButton.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}

TMButton.defaultProps = {
  className: '',
  loading: false
}

const Button = withStyles(styles)(TMButton);
export { Button };
