import { layout, colors } from "../../styles";

export default theme => ({
  root: {
    flexGrow: 1,
  },
  linksContainer: {
    display: layout.fx,
    alignItems: layout.ct,
    [theme.breakpoints.down('sm')]: {
      display: layout.none,
    },
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 0,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      display: layout.none,
    },
    [theme.breakpoints.up('md')]: {
      display: layout.bl,
    },
    "&:hover": {
      backgroundColor: colors.transparent
    }
    
  },
  hamburgerButton: {
    marginLeft: -12,
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      display: layout.bl,
    },
    [theme.breakpoints.up('md')]: {
      display: layout.none,
    },
  },
  button: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
      padding: '5px 8px'
    },
  },
  languageIcon: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing.unit,
    },
  },
  appBar: {
    // borderBottom: '1px solid rgba(0,0,0,0.1)'
    // [theme.breakpoints.up('md')]: {
    //   display: layout.none,
    // },
  },
  linkButton: {
    "&:hover": {
      backgroundColor: colors.transparent
    }
  },
  controls: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: 20,
    },
  },
  controlButtonLeft: {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
  },
  controlButtonRight: {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0,
  },
  headerIconLink: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  hamburgerMenu: {
    [theme.breakpoints.down('sm')]: {
      display: layout.bl,
    },
    [theme.breakpoints.up('md')]: {
      display: layout.none,
    },
  },
  toolbar: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 17,
    },
  },
  translateButton: {
    extends: 'button',
  },
  translateButtonMobile: {
    extends: 'button',
    [theme.breakpoints.down('sm')]: {
      display: layout.none,
    },
  },
  actionsContainer: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  controlsContainer: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      justifyContent: layout.sb
    },
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  languangeContainer: {
    position: layout.ab,
    bottom: 10,
    left: 10,
  }
});