import * as types from './types';

const openDialog = address => ({
	type: types.DIALOG_OPEN,
	payload: address
});

const closeDialog = () => ({
	type: types.DIALOG_CLOSE,
});

const setGoogleMapReference = reference => ({
	type: types.SET_GOOGLE_MAP_REFERENCE,
	payload: reference,
});

const setCongregationId = id => ({
	type: types.SET_CONGREGATION_ID,
	payload: id,
});

const setGoogleSelectedPlace = place => ({
	type: types.SET_GOOGLE_SELECTED_PLACE,
	payload: place,
});

const showLeftSide = side => ({
	type: types.RESPONSIVE_SHOW_LEFT_SIDE,
	payload: side,
});

const toggleSnack = (message = '') => ({
	type: types.TOGGLE_SNACK,
	payload: message,
});

const toggleConfirm = (show = false) => ({
	type: types.TOGGLE_CONFIRM,
	payload: show,
});

const setActiveCard = card => ({
	type: types.SET_ACTIVE_CARD,
	payload: card,
});

const setUser = user => ({
	type: types.SET_AUTH_USER,
	payload: user || {},
});

const setUniqueUserId = id => ({
	type: types.SET_UNIQUE_USER_ID,
	payload: id,
});

export {
	openDialog,
	closeDialog,
	setCongregationId,
	setGoogleMapReference,
	setGoogleSelectedPlace,
	showLeftSide,
	toggleSnack,
	toggleConfirm,
	setActiveCard,
	setUser,
	setUniqueUserId
};
