import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withLocalize } from 'react-localize-redux';
import classnames from 'classnames';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MaterialCard from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import ButtonBase from '@material-ui/core/ButtonBase';
import PlaceIcon from '@material-ui/icons/Place';

import { Text, CardAction } from '..';
import { notAtHomeOperations } from '../../state/ducks/NotAtHome';
import { commonOperations } from '../../state/ducks/Common';

import styles, { notes as noteStyle } from './styles';

class SmartCard extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    subheader: PropTypes.string,
    address: PropTypes.string.isRequired,
    selectedHomes: PropTypes.arrayOf(PropTypes.string).isRequired,
    activeCard: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    id: PropTypes.string.isRequired,
    locateCardOnMap: PropTypes.func.isRequired,
    notes: PropTypes.string,
    location: PropTypes.object,
  };

  static defaultProps = {
    subheader: '',
    notes: '',
    onClick: null,
    location: {}
  }
  
  onCardSelect = e => {
    const { onClick, locateCardOnMap } = this.props;

    if (onClick) {
      return onClick(this.props);
    }

    return locateCardOnMap(e, this.props);
  }

  render() {
    const {
      classes,
      address,
      notes,
      selectedHomes,
      subheader,
      activeCard,
      location,
      id
    } = this.props;

    const cardSelected = selectedHomes.some(identity => identity === id);

    const isActiveCard = activeCard.id === id;

    const cardSelectedStyles = cardSelected ? classes.cardSelected : classes.card;
    const cardActiveStyles = isActiveCard ? classes.cardActive : classes.card;

    return (
      <React.Fragment>
        <MaterialCard elevation={24} className={classnames(cardActiveStyles, cardSelectedStyles)}>
          <ButtonBase className={classes.base} onClick={this.onCardSelect}>
            <CardAction
              selected={cardSelected}
              subheader={subheader}
              cardProps={this.props}
            />
            <CardContent className={classes.cardContent} style={notes ? { paddingBottom: 4 } : {}}>
              <div className={classes.contentContainer}>
                {location.lat && <PlaceIcon className={classes.placeIcon} nativeColor="#ea4335" />}
                <Text>
                  {address}
                </Text>
              </div>
            </CardContent>
            <div className={classes.notesAction}>
              <div className={classes.notesContainer}>
                <Text maxLine="1" style={noteStyle}>
                  {notes}
                </Text>
              </div>
            </div>
          </ButtonBase>
        </MaterialCard>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
	const { notAtHome, common } = state;
	return { ...notAtHome, ...common };
};

const mapDispatchToProps = {
	selectHome: notAtHomeOperations.selectHome,
	setActiveCard: commonOperations.setActiveCard,
	toggleConfirm: commonOperations.toggleConfirm,
	locateCardOnMap: commonOperations.locateCardOnMap,
};

const enhance = compose(
  connect(
      mapStateToProps,
      mapDispatchToProps,
  ),
  withLocalize,
  withStyles(styles)
);

const Card = enhance(SmartCard);

export { Card }

