import firebase from './firebase';

class MetaService {
  constructor() {
    this.firestore = firebase.firestore();
  }

  create(collection, userId) {
    const payload = {
      lastUpdateBy: userId,
      lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
    };

    return this.firestore
      .collection(collection)
      .doc('meta')
      .set(payload)
      .then(() => payload);
  }

  subscribe(collection, onNext, onError) {
    // const db = this.firestore.collection(`meta/${collection}/lastUpdateBy`);
    const db = this.firestore.collection(collection)

  	const unsubscriber = db.onSnapshot(querySnapshot => {
      const data = {};
      
      if (querySnapshot.metadata.hasPendingWrites){
        return;
      }

  		querySnapshot.forEach(documentSnapshot => {
  			data[documentSnapshot.id] = {
          ...documentSnapshot.data(),
          ref: documentSnapshot.ref,
        };
      });

  		onNext(data);
  	}, onError);

    // this.subscriberList.push(unsubscriber);    

  	return unsubscriber;
  }
}

const Meta = new MetaService();
export { Meta };
