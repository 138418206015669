const initializePlaces = (props, address = 'address', callback = () => {}) => {
  const { google } = window;
  const { googleMapReference, setGoogleSelectedPlace } = props;
  const map = googleMapReference;
  
  const inputNode = document.getElementById(address);

  const autocomplete = new google.maps.places.Autocomplete(inputNode);

  
  autocomplete.bindTo('bounds', map);

  autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);


  autocomplete.addListener('place_changed', () => {

    const place = autocomplete.getPlace();

    setGoogleSelectedPlace(place);

    let address = '';

    if (place.address_components) {
      address = [
        ((place.address_components[0] && place.address_components[0].short_name) || ''),
        ((place.address_components[1] && place.address_components[1].short_name) || ''),
        ((place.address_components[2] && place.address_components[2].short_name) || '')
      ].join(' ');
    }

    callback({ ...place, formattedaddress: address });
  });
}

export { initializePlaces };