const padding = {
	sm: 10,
	md: 20,
	lg: 30,
	xl: 40,
};

const layout = {
	ab: 'absolute',
	rl: 'relative',
	fxd: 'fixed',
	ct: 'center',
	cl: 'column',
	st: 'stretch',
	sb: 'space-between',
	sa: 'space-around',
	fe: 'flex-end',
	bl: 'block',
	ib: 'inline-block',
	none: 'none',
	wp: 'wrap',
	rw: 'row',
	lt: 'left',
	rt: 'right',
	auto: 'auto',
	fx: 'flex',
	fs: '100%',
	hs: '50%',
	fvh: '100vh',
	fvw: '100vw',
	hvh: '50vh',
	hvw: '50vw',
};

export { padding, layout };
