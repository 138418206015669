/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withLocalize, Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dialog, Button, Alert } from '../../components';
import styles from './styles';
import LanguageButton from '../../components/AppBar/languageButton'
import users from './users';
import loginTranslations from '../../translations/login.json';
import { FormHelpers } from '../../utils';

class LoginDialog extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    addTranslation: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    congregationId: PropTypes.string.isRequired,
    status: PropTypes.string,
    location: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
  }

  static defaultProps = {
    status: null,
    location: {}
  }
  
  componentWillMount() {
    const { addTranslation } = this.props;
    addTranslation(loginTranslations);
  }

  componentDidMount() {
    this.signInFromQuery();
  }

  signInFromQuery = () => {
    const { location, setFieldValue, submitForm } = this.props;
    const query = new URLSearchParams(location.search); 
    const code = query.get('code');
    if (code) {
      setTimeout(() => {
        setFieldValue('user', users[0]);
        setFieldValue('code', code);
        submitForm();
      }, 1);
    }
  }

  onChange = (name, e) => {
    const { handleChange, setFieldTouched } = this.props;
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  renderSelect = () => {
    const { values, translate, classes } = this.props;
    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          name="user"
          aria-label={values.user}
          className={classes.group}
          value={values.user}
          onChange={this.onChange.bind(null, 'user')}
        >
          <FormControlLabel
            value={users[0]}
            control={<Radio />}
            label={translate('publisher')}
          />
          <FormControlLabel
            value={users[1]}
            control={<Radio />}
            label={translate('servant')}
          />
        </RadioGroup>
      </FormControl>
    )
  }

  renderErrorMessage = () => {
    const { classes, status, isSubmitting } = this.props;
    
    if (!status || isSubmitting) {
      return null;
    }

    return (
      <Alert
        variant="error"
        message={status}
        className={classes.errorSnack}
      />
    )
  }

  renderSampleMessage() {
    const { congregationId, classes, translate } = this.props;
    if (congregationId !== 'sample') {
      return null;
    }

    return (
      <Typography className={classes.sample} variant="button">
        {translate('login.sample_message')}
      </Typography>
    )
  }

  render = () => {
    const {
      handleBlur,
      values,
      handleSubmit,
      isValid,
      isSubmitting,
      classes,
      translate
    } = this.props;

    const label = translate => translate('login.textField', { user: translate(values.user) });   
    
    return (
      <React.Fragment>
        <div id="login-backgound" />
        <form onSubmit={handleSubmit}>
          <Dialog
            open
            onClose={this.handleDialogClose}
            aria-labelledby="login-dialog-title"
            fullWidth
          >
            <DialogTitle id="login-dialog-title">{translate('login.title')}</DialogTitle>
            <DialogContent>
              {this.renderSampleMessage()}  
              {this.renderSelect()}
              <Translate>
                {({ translate }) => (
                  <TextField
                    autoFocus
                    helperText={FormHelpers.renderHelperText(this.props, 'code')}
                    error={FormHelpers.showError(this.props, 'code')}
                    onChange={this.onChange.bind(null, 'code')}
                    onBlur={handleBlur}
                    value={values.code}
                    margin="dense"
                    id="code"
                    label={label(translate)}
                    fullWidth
                    placeholder={label(translate)}
                  />
                )}
              </Translate>
            </DialogContent>
            <DialogActions className={classes.actions}>
              <LanguageButton topBarMobile={false} size="small" />
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
              >
                {translate('login.button')}
              </Button>
            </DialogActions>
            {this.renderErrorMessage()}
          </Dialog>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
	const { common } = state;
	return common;
};

const enhance = compose(
  withLocalize,
  withRouter,
  connect(mapStateToProps),
  withStyles(styles),
);

export default enhance(LoginDialog);