import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { congregationGeolocaiton, calculateDistance } from '../../../../utils/googleMap';

import styles from './styles';
import BootstrapInput from './select';
import { firebaseDate } from '../../../../utils';

class FilterButton extends Component {

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ])),
    toggleSnack: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
  }

  static defaultProps = {
    data: []
  }

  criteria = {
    n: 0,
    o: 1,
    c: 2,
  }

  state = {
    sort: this.criteria.n,
    myLocation: null,
  };

  componentWillMount() {
    const { sort } = this.state;
    this.sort(sort);
  }

  componentDidMount() {
    this.getLocation();    
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.props;
    if (nextProps.data.length !== data.length) {
      const { sort } = this.state;
      this.sort(sort);
    }
  }

  sort = v => {
    const { myLocation } = this.state;
    const { n, o, c } = this.criteria;
    const { data } = this.props;

    const sorted = _.sortBy(data, d => firebaseDate(d));

    if (v === n) {
      return sorted.reverse();
    }

    if (v === o) {
      return sorted;
    }

    if (v === c) {
      if (myLocation) {
        const locationHomes = [];

        // add distance from user
        data.forEach(home => {
          if (home.location) {
            locationHomes.push({
              ...home,
              distance: calculateDistance(
                myLocation.lat,
                myLocation.lng,
                home.location.lat,
                home.location.lng
              )
            });
          } else {
            locationHomes.push({
              ...home,
              distance: 10000000
            })
          }
        });

        const sortedDistanceHomes = locationHomes.sort((a,b) => {
          if (a.distance < b.distance)
            return -1;
          if (a.distance > b.distance)
            return 1;
          return 0;
        });

        return sortedDistanceHomes
      }
    }

    return v;
  }

  handleChange = event => {
    const { value } = event.target;
    const { updateData } = this.props;
    updateData(this.sort(value));
    this.setState({ sort: value });
  };

  setLocation = (lat, lng) => {
    this.setState({ myLocation: { lat, lng }})
  }

  ipLookUp = () => {
    const { toggleSnack, translate } = this.props;
    fetch('http://ip-api.com/json')
    .then(response =>  response.json())
    .then(res => this.setLocation(res.lat, res.lon))
    .catch(() => {
      toggleSnack(translate('filter.location_unavailble'));
      this.setLocation(
        congregationGeolocaiton.lat,
        congregationGeolocaiton.lng
      );
    });
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        // TODO: Bug. translate always returns undefined when you first call it
         this.setLocation(
           position.coords.latitude,
           position.coords.longitude
        );
      },
      e => {
        console.error('An error has occured while retrieving location', e.message);
        this.ipLookUp();
      }, {timeout:10000});
    } else {
      // geolocation is not supported
      console.log('geolocation is not enabled on this browser')
      this.ipLookUp();
    }
  }

  render() {
    const { sort, myLocation } = this.state;
    const { n, o, c } = this.criteria;
    const { classes, translate } = this.props;

    // NOTE: Important to set as a property and use the property because in a toggle,
    // if not it will show the error of no translation Id
    this.notAvailableTranslate = translate('filter.location_unavailble');

    return (
      <form autoComplete="off">
        <FormControl className={classes.formControl}>
          <Typography className={classes.label} variant="button">
            {translate('filter.sort_by')}
          </Typography>
          <Select
            elevation={24}
            value={sort}
            onChange={this.handleChange}
            input={<BootstrapInput name="sort" id="sort-customized-select" />}
          >
            <MenuItem value={n}>{translate('filter.newest')}</MenuItem>
            <MenuItem value={o}>{translate('filter.oldest')}</MenuItem>
            {myLocation && <MenuItem value={c}>{translate('filter.closest')}</MenuItem>}
          </Select>
        </FormControl>
      </form>
    );
  }
}

FilterButton.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const enhance = compose(
  // withLocalize,
  withStyles(styles),
);

export default enhance(FilterButton);