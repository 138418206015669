import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import { localizeReducer } from 'react-localize-redux';
import { createLogger } from 'redux-logger';
// https://github.com/alexnm/re-ducks
// https://github.com/jthegedus/re-ducks-examples

import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import * as reducers from './ducks';
import { isProduction } from '../utils';

const persistConfig = {
  key: 'root',
	storage,
	whitelist: ['myNotAtHomes']
}

const rootReducer = combineReducers({
	localize: localizeReducer,
	...reducers
});

const persistedReducer = persistReducer(
	persistConfig,
	rootReducer
);

const logger = createLogger({});

let middlewares = [thunk];

if (!isProduction) {
	middlewares = [...middlewares, logger];
}

export const store = createStore(
	persistedReducer,
	{},
	compose(composeWithDevTools(applyMiddleware(...middlewares))),
);

export const persistor = persistStore(store);
