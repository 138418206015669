import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import LanguageButton from './languageButton';
import Logo from './logo';
import styles from './styles';

class AppBarDrawer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    showLeftSide: PropTypes.func.isRequired,
    routes: PropTypes.array.isRequired,
    congregationId: PropTypes.string.isRequired,
  }

  onClick= route => {
    const { history, onClose } = this.props;
    history.push(route);
    onClose();
  }

  render() {
    const { classes, match, location, routes, congregationId, history, showLeftSide, ...rest } = this.props;

    const paths = location.pathname.split('/');
    const path = paths[paths.length - 1];    
    
    const sideList = (
      <div className={classes.list}>
        <Logo style={{ paddingLeft: 15, paddingTop: 15 }} />
        <List>
          {routes.map(r => (
            <ListItem
              button
              key={r.route}
              selected={(path === r.route) || (path === congregationId && r.route === routes[0].route)}
              onClick={() => {
                // make sure you move away from the map
                showLeftSide(true);
                this.onClick(`${match.url}/${r.route}`)
              }}
            >
              <ListItemText primary={r.translatedRoute} />
            </ListItem>
          ))}
        </List>
        <div className={classes.languangeContainer}>
          <LanguageButton topBarMobile={false} />
        </div>
      </div>
    );

    return (
      <div>
        <Drawer {...rest}>
          {sideList}
        </Drawer>
      </div>
    );
  }
}

AppBarDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  withStyles(styles),
)

export default enhance(AppBarDrawer);