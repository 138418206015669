import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import Login from '../screens/Login'
import { commonOperations } from '../state/ducks/Common';
import { onChange } from '../utils';

class PrivateRoute extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    computedMatch: PropTypes.object.isRequired,
    setCongregationId: PropTypes.func.isRequired,
    congregationId: PropTypes.string.isRequired,
  }

  componentWillMount() {
    const { setCongregationId, computedMatch } = this.props;
    setCongregationId(computedMatch.params.congregation);
  }

  componentWillReceiveProps(nextProps) {
    const { setCongregationId, computedMatch } = this.props;
    onChange(computedMatch.params.congregation, nextProps.computedMatch.params.congregation, id => {
      setCongregationId(id);
    });
  }

  render() {
    const { component: Component, user, ...rest } = this.props;

    const { congregationId } = this.props;
  
    const render = props => {
    
      if (_.isNull(user)) {
        return null;
      }
  
      if (!user.email) {
        return <Login />
      }

      if (user.email.split('_')[0] !== congregationId) {
        return <Login />
      }

      return <Component {...props} />
    }

    return (
      <Route
        {...rest}
        render={render}
      />
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  user: PropTypes.object,
}

PrivateRoute.defaultProps = {
  user: null
}

const mapStateToProps = state => {
	const { common } = state;
	return {
    user: common.user,
    congregationId: common.congregationId
  };
};

const mapDispatchToProps = {
	setCongregationId: commonOperations.setCongregationId,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
