import uuid from 'uuidv4';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { Switch, Redirect, Route } from 'react-router-dom';
import { Loader } from '../components';
import globalTranslations from '../translations/global.json';
import { languages } from '../translations';
import { onLanguageChange, dateLocale } from '../utils';
import Main from './main';
import { commonOperations } from '../state/ducks/Common';
import PrivateRoute from './privateRoute';

class Navigation extends Component {
  static propTypes = {
    initialize: PropTypes.func.isRequired,
    activeLanguage: PropTypes.object,
    authenticate: PropTypes.func.isRequired,
    setUniqueUserId: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    congregationId: PropTypes.string.isRequired,
  }

  static defaultProps = {
    activeLanguage: {}
  }

  localStorageLocalizationKey = 'taichung-ministry-language-code';

  userId = 'taichung-ministry-identifier';

  constructor(props) {
    super(props);
    const { initialize } = this.props;

    const defaultLanguage = window.localStorage.getItem(this.localStorageLocalizationKey) || languages[1].code;

    initialize({
      languages,
      translation: globalTranslations,
      options: { renderToStaticMarkup, defaultLanguage }
    });
  }

  componentWillMount() {
    const { authenticate } = this.props;
    authenticate(this.configureUserId);
  }

  componentDidMount() {
    const { activeLanguage } = this.props;
    dateLocale(activeLanguage.code);
  }

  componentDidUpdate(prevProps) {
    const { activeLanguage } = this.props;
    const curLangCode = activeLanguage && activeLanguage.code;
  
    onLanguageChange(({ code }) => {
      window.localStorage.setItem(this.localStorageLocalizationKey, curLangCode);
      dateLocale(code.toLowerCase());
    })(prevProps, this.props);
  }

  configureUserId = () => {
    const { setUniqueUserId, congregationId } = this.props;
    const userId = window.localStorage.getItem(this.userId);
    if (userId) {
      setUniqueUserId(userId);
      return userId;
    }

    const id = `${congregationId}-${uuid()}`;
    setUniqueUserId(userId);
    window.localStorage.setItem(this.userId, id);
    return id;
  }

  render() {
    const { translate } = this.props;
    document.title = translate('app_name');

    return (
      <React.Fragment>
        <React.Suspense fallback={Loader}>
          <Switch>
            <Route
              exact
              path="/"
              name="root"
              render={props => (
                <Redirect
                  to={{
                    pathname: '/sample',
                    state: { from: props.location }
                  }}
                />
              )}
            />
            <PrivateRoute
              path="/:congregation"
              name="main"
              component={Main}
            />
          </Switch>
        </React.Suspense>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
	const { common } = state;
	return common;
};

const mapDispatchToProps = {
	authenticate: commonOperations.authenticate,
	setUniqueUserId: commonOperations.setUniqueUserId,
};

const enhance = compose(
  connect(
      mapStateToProps,
      mapDispatchToProps,
  ),
  withLocalize
);

export default enhance(Navigation);

