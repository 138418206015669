import { layout, padding } from "../../../../styles";

export default theme => ({
  root: {
    flexGrow: 1,
    padding: padding.md,
    // paddingTop: 4
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: layout.ct,
    color: theme.palette.text.secondary,
  },
  checkoutContainer: {
    position: 'fixed',
    margin: padding.md,
    bottom: 0,
    left: layout.hs,
    transform: `translateX(-${layout.hs})`,
    zIndex: 999,
  }
});