import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { AppBar, SplitScreen, Map, Loader, Snack } from '../components';
import { routes } from '../utils';

// move to better place
import CheckoutButton from '../screens/NotAtHome/CheckoutButton/checkout';

const ServiceSchedule = React.lazy(() => import('../screens/ServiceSchedule'));
const NotAtHome = React.lazy(() => import('../screens/NotAtHome'));
const MyNotAtHomes = React.lazy(() => import('../screens/NotAtHome/MyNotAtHomes/index'));

const Main = ({ match }) => {
  return (
    <React.Fragment>
      <AppBar />
      <Snack />
      <CheckoutButton />
      <SplitScreen>
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path={match.path} name={_.startCase(routes[0])} render={props => <ServiceSchedule {...props} />} />
            <Route path={`${match.path}/${routes[0]}`} name={_.startCase(routes[0])} render={props => <ServiceSchedule {...props} />} />
            <Route path={`${match.path}/${routes[1]}`} name={_.startCase(routes[1])} render={props => <NotAtHome {...props} />} />
            <Route path={`${match.path}/${routes[2]}`} name={_.startCase(routes[2])} render={props => <MyNotAtHomes {...props} />} />
          </Switch>
        </React.Suspense>
        <Map />
      </SplitScreen>
    </React.Fragment>
  )
}

Main.propTypes = {
  match: PropTypes.object.isRequired,
}

export default Main;