import { colors } from '../../styles';

export default () => ({
  cardHeader: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  deleteMenuOption: {
    color: colors.red,
  },
  menuButton: {
    marginTop: 0
  }
});