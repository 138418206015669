import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withLocalize } from 'react-localize-redux';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';

import MoreIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/CheckCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { commonOperations } from '../../state/ducks/Common';

import { translate, Permissions } from '../../utils';

import styles from './styles';
import { colors } from '../../styles';
import DetailsDialog from '../Card/DetailsDialog';

class SmartCardAction extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    cardProps: PropTypes.object.isRequired,
    subheader: PropTypes.string,
    setActiveCard: PropTypes.func.isRequired,
    activeCard: PropTypes.object.isRequired,
    toggleConfirm: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    iconStyles: PropTypes.object,
    showMenu: PropTypes.bool,
    locateCardOnMap: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.node
    ]),
    titleTypographyProps: PropTypes.object,
    className: PropTypes.string,
  };

  static defaultProps = {
    subheader: '',
    selected: false,
    iconStyles: {},
    showMenu: true,
    title: '',
    titleTypographyProps: {},
    className: ''
  }

  state = {
    anchorEl: null,
    showDetailsDialog: false,
  };

  handleMenu = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  closeDetailsDialog = event => {
    event.stopPropagation();
    const { setActiveCard } = this.props;
    this.setState({ showDetailsDialog: false });
    setActiveCard({});
  };
  
  handleClose = event => {
    event.stopPropagation()
    this.setState({ anchorEl: null });
  };

  locateOnMap = e => {
    const { locateCardOnMap, cardProps } = this.props;
    e.stopPropagation();
    locateCardOnMap(e, cardProps);
    this.handleClose(e);
  }

  deleteCard = e => {
    e.stopPropagation();
    const { toggleConfirm } = this.props;
    toggleConfirm(true);
    this.setActiveCard();
    this.handleClose(e);
  }

  showCardDetails = e => {
    e.stopPropagation();
    this.setActiveCard();
    this.setState({ showDetailsDialog: true });
    this.handleClose(e);
  }

  setActiveCard = () => {
    const { cardProps, setActiveCard } = this.props;
    const { address, updatedAt, id, createdAt, notes, date, time } = cardProps;
    setActiveCard({
      id,
      address,
      updatedAt,
      createdAt,
      notes,
      date,
      time
    });
  }  
  
  renderMenu = () => {
    const { anchorEl } = this.state;
    const { classes, user, cardProps } = this.props;
    const menuOpen = Boolean(anchorEl);

    const showDelete = Permissions.isServant() || user.userId === cardProps.postedBy;
    
    return (
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={menuOpen}
        onClose={this.handleClose}
        elevation={24}
      >
        <MenuItem onClick={this.showCardDetails}>{translate('details')}</MenuItem>
        {/* <MenuItem onClick={this.handleClose}>{translate('card_menu.edit')}</MenuItem> */}
        <MenuItem onClick={this.locateOnMap}>{translate('locate_on_map')}</MenuItem>
        { showDelete && <MenuItem onClick={this.deleteCard} className={classes.deleteMenuOption}>{translate('delete')}</MenuItem>}
      </Menu>
    );
  }
  
  renderAction = () => {
    const { classes, selected, iconStyles, showMenu } = this.props;
    const { anchorEl } = this.state;
    const menuOpen = Boolean(anchorEl);

    if (!showMenu) {
      return null;
    }

    if (selected) {
      return (
        <IconButton disabled>
          <CheckIcon style={{ fontSize: 25 }} nativeColor={colors.green} />
        </IconButton>
      );
    }

    return (
      <IconButton
        className={classes.menuButton}
        color="inherit"
        aria-label="Menu"
        aria-owns={menuOpen ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={this.handleMenu}
      >
        <MoreIcon style={iconStyles} />
      </IconButton>
    );
  }

  render() {
    const {
      classes,
      subheader,
      activeCard,
      title,
      titleTypographyProps,
      className
    } = this.props;

    const { showDetailsDialog } = this.state;
    
    return (
      <React.Fragment>
        <DetailsDialog
          open={showDetailsDialog}
          handleClose={this.closeDetailsDialog}
          activeCard={activeCard}
        />
        <CardHeader
          className={classnames(classes.cardHeader, className)}
          subheader={subheader}
          subheaderTypographyProps={{ style: { fontSize: 14 }}}
          action={this.renderAction()}
          title={title}
          titleTypographyProps={titleTypographyProps}
        />
        {this.renderMenu()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ common }) => common;

const mapDispatchToProps = {
	setActiveCard: commonOperations.setActiveCard,
	toggleConfirm: commonOperations.toggleConfirm,
	locateCardOnMap: commonOperations.locateCardOnMap,
};

const enhance = compose(
  connect(
      mapStateToProps,
      mapDispatchToProps,
  ),
  withLocalize,
  withStyles(styles)
);

const CardAction = enhance(SmartCardAction);

export { CardAction };

