import * as types from './types';

const loadMyNotAtHomesComplete = myNotatHomes => ({
	type: types.LOAD_MY_NOT_AT_HOME_COMPLETE,
	payload: myNotatHomes,
});

const deleteMyNotAtHome = id => ({
	type: types.DELETE_MY_NOT_AT_HOME,
	payload: id,
});

export { loadMyNotAtHomesComplete, deleteMyNotAtHome };
