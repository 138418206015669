
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Loader, Error, ConfirmationDialog } from '../../../../components';

import styles from './styles';

import { translate } from '../../../../utils';
import { commonOperations } from '../../../../state/ducks/Common';

class NotAtHomeList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    addTranslation: PropTypes.func.isRequired,
    setActiveCard: PropTypes.func.isRequired,
    activeCard: PropTypes.object,
    deleteloading: PropTypes.bool,
    confirmDialogProps: PropTypes.object,
    renderCard: PropTypes.func.isRequired,
    toggleConfirm: PropTypes.func.isRequired,
    confirm: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    showingHeader: PropTypes.bool,
  }

  static defaultProps = {
    error: '',
    activeCard: {},
    confirmDialogProps: {},
    deleteloading: false,
    showingHeader: false,
  }

  renderContent = () => {
    const { data, renderCard } = this.props;
    return data.map((item, i) => (
      <Grid container key={item.address} wrap="nowrap" item xs={12} md={6}>
        {renderCard(item, i)}
      </Grid>
    ));
  }

  renderDialog = () => {
    const { confirmDialogProps, activeCard, confirm, toggleConfirm, setActiveCard  } = this.props;
  
    if (!confirmDialogProps) {
      return null;
    }

    const handleClose = () => {
      setActiveCard({});
      toggleConfirm();
    };

    const onConfirm = () => {
      confirmDialogProps.confirm(handleClose);
    }

    const props = {
      title: translate('are_you_sure'),
      message: confirmDialogProps.message || translate(
        'want_to_delete',
        { data: { address: `"${activeCard.address}"` }}
      ),
      open: confirm,
      handleClose,
      onConfirm,
      loading: confirmDialogProps.loading
    }

    return (
      <ConfirmationDialog {...props} />
    )
  }

  render() {
    const {
      classes,
      loading,
      error,
      data,
      name,
      showingHeader
    } = this.props;    

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return (
        <Error message={error} />
      );
    }

    if (!showingHeader && !data.length) {
      return (
        <Error
          title={translate('empty_error_title', { data: { name: translate(name) } })}
          message={translate('empty_error_message', { data: { name: translate(name) } })}
        />
      );
    }

    if (showingHeader && !data.length) {
      // need to render dialog here because the header will need to access delete confirmation if servant
      return this.renderDialog();
    }

  return (
    <React.Fragment>
      {this.renderDialog()}
      <div className={classes.root}>
        <Grid container spacing={24}>
          {this.renderContent()}
        </Grid>
      </div>
    </React.Fragment>
    );
  }
}

const mapStateToProps = ({ common }) => common;

const mapDispatchToProps = {
	toggleConfirm: commonOperations.toggleConfirm,
	setActiveCard: commonOperations.setActiveCard,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withLocalize,
  withStyles(styles)
);

export default enhance(NotAtHomeList);