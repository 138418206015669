import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
import { notAtHomeOperations } from '../../../state/ducks/NotAtHome';
import Content from '../../Content';
import CheckoutButton from '.';
import NewNotAtHomeDialog from '../NewNotAtHomeDialog';
import Filter from '../Filter';
import { Card } from '../../../components';
import notAtHomeTranslations from '../../../translations/notathome.json';

import { routes } from '../../../utils';

class NotAtHome extends Component {
  static propTypes = {
    loadNotAtHome: PropTypes.func.isRequired,
    addTranslation: PropTypes.func.isRequired,
    selectedHomes: PropTypes.arrayOf(PropTypes.string),
    checkoutNotAtHomes: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    history: PropTypes.object,
    refresh: PropTypes.bool,
    checkingOutTerritories: PropTypes.bool,
  }

  static defaultProps = {
    selectedHomes: [],
    history: {},
    refresh: false,
    checkingOutTerritories: false,
  }

  constructor(props) {
    super(props);

    const { addTranslation } = this.props;
    addTranslation(notAtHomeTranslations);
  }

  render() {
    const {
      refresh,
      selectedHomes,
      checkoutNotAtHomes,
      history,
      loadNotAtHome,
      translate,
      checkingOutTerritories
    } = this.props;

    if (refresh) {
      return (
        <CheckoutButton onClick={() => loadNotAtHome()}>
          <RefreshIcon style={{ paddingRight: 6 }} />
          {translate('checkout.refresh')}
        </CheckoutButton>
      )
    }

    if (selectedHomes.length) {
      const title = `
        ${translate('checkout.checkout')}
        ${selectedHomes.length}
        ${translate('checkout.territories')}
      `;

      return (
        <CheckoutButton
          loading={checkingOutTerritories}
          onClick={() => checkoutNotAtHomes(() => history.push(routes[2]))}
        >
          {title}
        </CheckoutButton>
      )
    }

    return null;
  }
}

const mapStateToProps = state => {
	const { notAtHome, common  } = state;
	return { ...notAtHome, ...common };
};

const mapDispatchToProps = {
	loadNotAtHome: notAtHomeOperations.loadNotAtHome,
	deleteNotAtHome: notAtHomeOperations.deleteNotAtHome,
	selectHome: notAtHomeOperations.selectHome,
	checkoutNotAtHomes: notAtHomeOperations.checkoutNotAtHomes,
};

const enhance = compose(
  connect(
      mapStateToProps,
      mapDispatchToProps,
  ),
  withLocalize,
);

export default enhance(NotAtHome);