import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { translate } from '../../utils';
import { Button, Dialog } from '..'

const ConfirmationDialog = props => {
  const {
    title,
    message,
    open,
    handleClose,
    onConfirm,
    loading,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} variant="text">
          {translate('cancel')}
        </Button>
        <Button
          disabled={loading}
          onClick={onConfirm}
          autoFocus
          loading={loading}
        >
          {translate('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

ConfirmationDialog.defaultProps = {
  title: '',
  message: '',
  open: false,
  loading: false
}

export { ConfirmationDialog };