import _ from 'lodash';
import uuid from 'uuidv4';
import firebase from './firebase';

class CommonModel {
  constructor(collection = null, schema = {}) {
    this.schema = schema;
    this.collection = collection;
    this.subscriberList = [];
    this.fs = firebase.firestore;
    this.firestore = firebase.firestore();
    this.auth = firebase.auth();
    this.timestamp = firebase.firestore.FieldValue.serverTimestamp();

    this.verbage = {
      is: '==',
      deletedAt: 'deletedAt',
      email: 'email',
      phoneNumber: 'phoneNumber',
      fbId: 'facebookId',
      userName: 'userName',
      empty: null,
      asc: 'asc',
      desc: 'desc'
    };

    if (!collection) throw new Error(`No 'collection' parameter provided in constructor.`);
  }

  generateData(id) {
    return {
      id,
      ...this.schema,
      createdAt: this.timestamp,
      updatedAt: this.timestamp
      // deletedAt: null,
    };
  }

  createFirebasePayload(data = {}) {
    if (typeof data !== 'object')
    throw new Error(`Cannot create new document with non-object type 'data'.`);    

    const id = data.id || data.uid || uuid();

    return {
      ...this.generateData(id),
      ...data
    };
  }

  create(data) {

    const payload = this.createFirebasePayload(data);
    console.log(this.collection, payload);
    

    return this.firestore
      .collection(this.collection)
      .doc(payload.id)
      .set(payload)
      .then(() => payload);
  }

  exists(doc, value) {
    const { deletedAt, is, empty } = this.verbage;
    return this.firestore
      .collection(this.collection)
      .where(deletedAt, is, empty)
      .where(doc, is, value)
      .get()
      .then(querySnapshot => {
        let exists = false;

        querySnapshot.forEach(doc => {
          if (doc.exists) {
            exists = true;
          }
        });

        return exists;
      });
  }

  findIn(items, field) {
    const { deletedAt, is, empty } = this.verbage;
    const c = [];
    items.forEach(n => {
      this.firestore
        .collection(this.collection)
        .where(deletedAt, is, empty)
        .where(field, is, n)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            if (doc.exists) {
              c.push(doc.data());
            }
          });
        });
    });
    return c;
  }

  findById(id) {
    if (!id) return Promise.resolve(null);

    return this.firestore
      .collection(this.collection)
      .doc(id)
      .get()
      .then(documentSnapshot => {
        if (!documentSnapshot.exists) return null;

        return documentSnapshot.data();
      });
  }

  findOrCreateById(id, data) {
    return this.findById(id).then(result => {
      if (!result) {
        return this.create(data);
      }

      return result;
    });
  }

  updateById(id, data) {
    const payload = {
      updatedAt: this.timestamp,
      ...data
    };

    return this.firestore
      .collection(this.collection)
      .doc(id)
      .update(payload)
      .then(() => this.findById(id));
  }

  deleteById(id) {
    return this.firestore
      .collection(this.collection)
      .doc(id)
      .update({ deletedAt: this.timestamp, deleted: true })
      .then(() => this.findById(id));
  }

  permanentlyDeleteById(id) {
    return this.firestore
      .collection(this.collection)
      .doc(id)
      .delete()
      .then(() => this.findById(id));
  }

  subscribe(onNext, onError, order = '') {
    let db = this.firestore.collection(this.collection);

    if (order) {
      db = db.orderBy(order);
    }

  	const unsubscriber = db.onSnapshot(querySnapshot => {
      const data = [];
      
      // Because using on listener, without this, it sometimes renders twice
      if(querySnapshot.metadata.hasPendingWrites){
        return;
      }

  		querySnapshot.forEach(documentSnapshot => {
  			data.push({
          ...documentSnapshot.data(),
          ref: documentSnapshot.ref
        });
      });

  		return onNext(data);
  	}, onError);

    this.subscriberList.push(unsubscriber);    

  	return unsubscriber;
  }

  unsubscribe() {
  	this.subscriberList.forEach(unsubscriber => {
  		unsubscriber();
  	});

  	this.subscriberList = [];
  }

  deleteAll(data) {
    const batch = this.firestore.batch();

    data.forEach((doc) => {
      batch.delete(doc.ref);
    });

    return batch.commit();
  }

  setCollectionPrefix(sub) {
    if (this.collection.indexOf('-') === -1) {
      this.collection = `${sub}-${this.collection}`
    } 
  }

  bulkUpdate(data) {
    const batch = this.firestore.batch();

    _.map(data, (c, i) => {
      const ref = this.firestore.collection(this.collection).doc(i);
      batch.update(ref, c)
    });

    return batch.commit();
  }

  permanentlyDeleteCollection(data) {
    const batch = this.firestore.batch();

    data.forEach(i => {
      const ref = this.firestore.collection(this.collection).doc(i);
      batch.delete(ref)
    });

    return batch.commit();
  }
}

export { CommonModel };
