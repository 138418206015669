import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import PlaceIcon from '@material-ui/icons/Place';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import Notes from '@material-ui/icons/SpeakerNotes';
import { formatDate, translate, firebaseDate } from '../../../utils';

import styles from './styles';

function DetailsList(props) {
  const { classes, address, updatedAt, notes } = props;

  // const ua = updatedAt ? formatDate(updatedAt.toDate()) : '';
  const ca = updatedAt ? formatDate(firebaseDate(props)) : '';

  return (
    <List className={classes.root}>
      <ListItem>
        <Avatar>
          <PlaceIcon />
        </Avatar>
        <ListItemText primary={translate('address')} secondary={address} />
      </ListItem>
      {/* <ListItem>
        <Avatar>
          <CalendarIcon />
        </Avatar>
        <ListItemText primary={translate('updated_at')} secondary={ua} />
      </ListItem> */}
      <ListItem>
        <Avatar>
          <CalendarIcon />
        </Avatar>
        <ListItemText primary={translate('created_at')} secondary={ca} />
      </ListItem>
      { notes && (
        <ListItem>
          <Avatar>
            <Notes />
          </Avatar>
          <ListItemText primary={translate('notes')} secondary={notes} />
        </ListItem>
      )}
    </List>
  );
}

DetailsList.propTypes = {
  classes: PropTypes.object.isRequired,
  address: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  notes: PropTypes.string,
};

DetailsList.defaultProps = {
  updatedAt: '',
  notes: '',
};

export default withStyles(styles)(DetailsList);