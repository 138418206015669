import { combineReducers } from 'redux';
import * as types from './types';

/* State shape
{
	notAtHomeLoading: false,
	notAtHome: [],
	notAtHomeError: '',

	addNotAtHomeLoading: false,
	addNotAtHomeError: '',

	deleteNotAtHomeLoading: false,
	deleteNotAtHome: false,
	deleteNotAtHomeError: '',

	selectedHomes: [],
	activeCard: {};
	refresh: false,
	checkingOutTerritories: false
}
*/

const addOrRemove = (state, payload) => {
	const inState = state.some(item => item === payload);	

	if (inState) {
		return state.filter(item => item !== payload);
	}

	return [...state, payload]
}

const notAtHomeLoading = (state = false, action) => {
	switch (action.type) {
		case types.LOAD_NOT_AT_HOME:
			return true;
		case types.LOAD_NOT_AT_HOME_FAIL:
			return false;
		case types.LOAD_NOT_AT_HOME_COMPLETE:
			return false;
		default:
			return state;
	}
};

const notAtHome = (state = [], action) => {
	switch (action.type) {
		case types.LOAD_NOT_AT_HOME_COMPLETE:
			return action.payload;
		case types.ADD_NOT_AT_HOME_COMPLETE:
			return [action.payload, ...state];
		case types.DELETE_NOT_AT_HOME_COMPLETE:
			return state.filter(item => item.id !== action.payload);
		default:
			return state;
	}
};

const notAtHomeError = (state = '', action) => {
	switch (action.type) {
		case types.LOAD_NOT_AT_HOME:
			return '';
		case types.LOAD_NOT_AT_HOME_COMPLETE:
			return '';
		case types.LOAD_NOT_AT_HOME_FAIL:
			return action.payload;
		default:
			return state;
	}
};

const addNotAtHomeLoading = (state = false, action) => {
	switch (action.type) {
		case types.ADD_NOT_AT_HOME:
			return true;
		case types.ADD_NOT_AT_HOME_FAIL:
			return false;
		case types.ADD_NOT_AT_HOME_COMPLETE:
			return false;
		default:
			return state;
	}
};

const addNotAtHomeError = (state = '', action) => {
	switch (action.type) {
		case types.ADD_NOT_AT_HOME:
			return '';
		case types.ADD_NOT_AT_HOME_COMPLETE:
			return '';
		case types.ADD_NOT_AT_HOME_FAIL:
			return action.payload;
		default:
			return state;
	}
};

const selectedHomes = (state = [], action) => {
	switch (action.type) {
		case types.ADD_SELECTED_HOME:
			return addOrRemove(state, action.payload);
		case types.SET_SELECTED_HOMES:
			return action.payload;
		default:
			return state;
	}
};

const deleteNotAtHomeLoading = (state = false, action) => {
	switch (action.type) {
		case types.DELETE_NOT_AT_HOME:
			return true;
		case types.DELETE_NOT_AT_HOME_COMPLETE:
			return false;
		case types.DELETE_NOT_AT_HOME_FAIL:
			return false;
		default:
			return state;
	}
};

const deleteNotAtHome = (state = false, action) => {
	switch (action.type) {
		case types.DELETE_NOT_AT_HOME:
			return false;
		// case types.DELETE_NOT_AT_HOME_COMPLETE:
		// 	return true;
		case types.DELETE_NOT_AT_HOME_FAIL:
			return false;
		default:
			return state;
	}
};

const deleteNotAtHomeError = (state = false, action) => {
	switch (action.type) {
		case types.DELETE_NOT_AT_HOME:
			return '';
		case types.DELETE_NOT_AT_HOME_COMPLETE:
			return '';
		case types.DELETE_NOT_AT_HOME_FAIL:
			return action.payload;
		default:
			return state;
	}
};

const refresh = (state = false, action) => {
	switch (action.type) {
		case types.SET_REFRESH:
			return action.payload;
		default:
			return state;
	}
};

const checkingOutTerritories = (state = false, action) => {
	switch (action.type) {
		case types.CHECK_OUT_TERRITORY:
			return action.payload;
		default:
			return state;
	}
};

const reducer = combineReducers({
	notAtHomeLoading,
	notAtHome,
	notAtHomeError,
	addNotAtHomeLoading,
	addNotAtHomeError,
	selectedHomes,
	deleteNotAtHomeLoading,
	deleteNotAtHome,
	deleteNotAtHomeError,
	refresh,
	checkingOutTerritories
});

export default reducer;
