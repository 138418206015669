import { weight, layout } from '../../styles';

export const title = {
  fontWeight: weight.smb,
  fontSize: '0.9rem'
}

export default () => ({
  button: {
    marginTop: 6,
  },
  address: {
    fontWeight: weight.smb,
    color: 'grey',
  },
  icon: {
    paddingTop: 4
  },
  actionsContainer: {
    display: layout.fx,
    alignItems: layout.ct
  },
  addButton: {
    marginLeft: 6
  }
});