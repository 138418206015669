import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';

import MDAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CardActions from '@material-ui/core/CardActions';
import PlaceIcon from '@material-ui/icons/Place'
import ListIcon from '@material-ui/icons/List'
import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

import styles from './styles';
import { LinkButton, Button } from "..";
import { commonOperations } from '../../state/ducks/Common';

import { translate, googleMap, routes, Permissions, activeRoute } from '../../utils';

import Drawer from './drawer';
import Logo from './logo';
import LanguageButton from './languageButton';
import { Auth } from '../../services';

class MenuAppBar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    openDialog: PropTypes.func.isRequired,
    showLeftSide: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    activeLanguage: PropTypes.object,
    responsiveShowLeftSide: PropTypes.bool,
    congregationId: PropTypes.string.isRequired,
  }

  static defaultProps = {
    activeLanguage: {},
    responsiveShowLeftSide: null,
  }

  state = {
    drawerOpen: false
  };

  renderLinks = () => {
    const { classes, congregationId } = this.props;
    
    return routes.map(route => (
      <LinkButton
        className={classes.linkButton}
        key={route}
        link={route}
        congregationId={congregationId}
      >
        {translate(_.snakeCase(route))}
      </LinkButton>
    ))
  }

  renderAddButton = () => {
    const { classes, openDialog, history } = this.props;

    const ac = activeRoute(history);
    const isHome = ac === routes[0] || ac === '';    

    if (!Permissions.isServant() && isHome) {
      return null;
    }

    if (ac === routes[2]) {
      return null;
    }

    return (
      <Button
        onClick={() => openDialog()}
        className={classes.button}
      >
        <AddIcon />
        <div className={classes.headerIconLink}>
          {translate('add_new')}
        </div>
      </Button>
    )
  }

  renderControls = () => {
    const { classes, showLeftSide, responsiveShowLeftSide } = this.props;

    const isActive = c => c ? 'contained' : 'outlined';
    const leftActive = _.isNull(responsiveShowLeftSide) || responsiveShowLeftSide === true;
    const rightActive = responsiveShowLeftSide === false;

    return (
      <div className={classes.controls}>
        <Button
          size="small"
          variant={isActive(leftActive)}
          className={classes.controlButtonLeft}
          onClick={() => showLeftSide(true)}
        >
          <ListIcon />
        </Button>
        <Button
          size="small"
          variant={isActive(rightActive)}
          className={classes.controlButtonRight}
          onClick={() => {
            showLeftSide(false);
            if (googleMap.markers.length) {
              setTimeout(googleMap.fitBounds, 200);
            }
          }}
        >
          <PlaceIcon />
        </Button>
      </div>
    )
  }
 
  onLogoClick = () => {
    const { history, congregationId } = this.props;
    history.push(`/${congregationId}`);
    Auth.logout();
  }

  renderBalancer = () => {
    const { history } = this.props;
    const ac = activeRoute(history);

    if (ac === routes[2]) {
      return <div />;
    }

    if (!Permissions.isServant() && ac !== routes[1]) {
      return <div />;
    }

    return null;
  }

  render() {
    const {
      classes,
      history,
      activeLanguage: { name = '' },
      congregationId,
      showLeftSide
    } = this.props;

    const { drawerOpen } = this.state;

    return (
      <div className={classes.root}>
        <Drawer
          routes={routes.map(r => ({ route: r, translatedRoute: translate(_.snakeCase(r)) }))}
          language={translate(name.toLocaleLowerCase())}
          open={drawerOpen}
          onClose={() => this.setState({ drawerOpen: false })}
          history={history}
          renderLanguageButton={() => null}
          congregationId={congregationId}
          showLeftSide={showLeftSide}
        />
        <MDAppBar elevation={24} color="inherit" position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <CardActions className={classes.linksContainer}>
              <Logo />
              <div className={classes.headerIconLink}>
                {this.renderLinks()}
              </div>
            </CardActions>
            <div className={classes.actionsContainer}>
              <CardActions className={classes.controlsContainer}>
                <IconButton
                  onClick={() => this.setState({ drawerOpen: true })}
                  className={classes.hamburgerMenu}
                  color="inherit"
                  aria-label="Menu"
                >
                  <MenuIcon fontSize="inherit" />
                </IconButton>
                {this.renderControls()}
                {this.renderAddButton()}
                <LanguageButton />
                {this.renderBalancer()}
              </CardActions>
            </div>
          </Toolbar>
        </MDAppBar>
      </div>
    );
  }
}

const mapStateToProps = state => {
	const { notAtHome, common } = state;
	return { ...notAtHome, ...common };
};

const mapDispatchToProps = {
	openDialog: commonOperations.openDialog,
	showLeftSide: commonOperations.showLeftSide,
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withLocalize,
  withStyles(styles),
)

const AppBar = enhance(MenuAppBar);
export { AppBar };
