import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const isProduction = process.env.NODE_ENV === 'production';
// const isProduction = true;

const settings = { };

const prodConfig = {
  apiKey: "AIzaSyBZZvNNRK7BMS4p1B1amV1mLCAYpzr72YE",
  authDomain: "production-21e08.firebaseapp.com",
  databaseURL: "https://production-21e08.firebaseio.com",
  projectId: "production-21e08",
  storageBucket: "production-21e08.appspot.com",
  messagingSenderId: "751326187619",
  appId: "1:751326187619:web:2d51b1f4c238803d"
};

const devConfig = {
  apiKey: "AIzaSyDujWlYgISt46CgAVhBJex4pSTDVO5ChoA",
  authDomain: "development-81cdb.firebaseapp.com",
  databaseURL: "https://development-81cdb.firebaseio.com",
  projectId: "development-81cdb",
  storageBucket: "development-81cdb.appspot.com",
  messagingSenderId: "942597794513"
};

const config = isProduction ? prodConfig : devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

firebase.firestore().settings(settings);

export default firebase;
