import axios from 'axios';
import { formatDate, firebaseDate, Permissions, isMobile } from '.';

const styles = require('../styles/map.json');

const congregationGeolocaiton = { lat: 24.125330, lng: 120.663399 };

class GoogleMap {
  constructor() {
    this.map = null;
    this.markers = [];

    // google
    this.bounds = null;
    this.infoWindow = null;
    this.infoWindowContent = null;
    this.geocoder = null;
    this.servuce = null;
    this.geocoderCallback = () => {};
  }

  init(cb = () => {}) {

    this.map = new window.google.maps.Map(document.getElementById('google-map'), {
      zoom: 17,
      center: congregationGeolocaiton,
      styles
      // mapTypeId: 'terrain'
    });

    this.geocoder = new window.google.maps.Geocoder();

    // InfoWindow
    this.infoWindow = new window.google.maps.InfoWindow();
    this.infoWindowContent = document.getElementById('infowindow-content');
    this.infoWindow.setContent(this.infoWindowContent);
    this.directionsButton = document.getElementById('directions-button');

    // Bounds
    this.bounds = new window.google.maps.LatLngBounds();

    // Service
    this.service = new window.google.maps.places.PlacesService(this.map);
    
    if (Permissions.isServant()) {
      this.map.addListener('click', (event) => {
        this.geocoder.geocode({ latLng: event.latLng }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              this.geocoderCallback(results[0]);
            }
          }
        });
      });
    }

    window.google.maps.event.addDomListener(window, 'resize', () => {
      if (!this.markers.length) {
        this.bounds.extend(congregationGeolocaiton);
      }
      this.fitBounds();
    });
  
    cb(this.map);
  }

  setGeocoderCallback = callback =>  {
    this.geocoderCallback = callback;
  }

  markerFocus = uid => {
    const marker = this.markers.find(({ id }) => id === uid);

    if (marker) {
      const zoomCoords = marker.getPosition();
      this.map.setZoom(17);
      this.map.panTo(zoomCoords);
      marker.openInfoWindow();
    }
  }

  addMarker = location => {
    const marker = new window.google.maps.Marker({
      position: location,
      map: this.map,
      // icon: {
      //   url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
      // }
    });

    this.markers.push(marker);

    return marker;
  }

  setMapOnAll(map) {
    for (let i = 0; i < this.markers.length; i+=1) {
      this.markers[i].setMap(map);
    }
  }

  clearMarkers = () => {
    this.setMapOnAll(null);
  }

  showMarkers = () => {
    this.setMapOnAll(this.map);
  }

  deleteMarkers = () => {
    this.clearMarkers();
    this.markers = [];
  }

  fitBounds = () => {
    this.map.fitBounds(this.bounds);
  }

  addMarkers = (markers, setActiveCard) => {
    if (!window.google || !window.google.maps) {
      return;
    }

    this.deleteMarkers();

    // resets bounds for when deleted
    this.bounds = new window.google.maps.LatLngBounds();

    markers.forEach(place => {
      const latLng = new window.google.maps.LatLng(place.lat, place.lng);
      const marker = this.addMarker(latLng);

      this.bounds.extend(latLng);
      this.fitBounds();

      const openInfoWindow = this.bindWindowToMarker(window.google, marker, place, setActiveCard, place.lat, place.lng);

      marker.setValues({
        id: place.id,
        openInfoWindow
      });

      marker.addListener('click', () => {
        setActiveCard(place);
        openInfoWindow();
      });

      // window.google.maps.event.addDomListener(document.getElementById('google-map'), 'resize', () => {
      //   window.google.maps.event.trigger(this.map, "resize");
      //   this.fitBounds();
      // });
    });
  }

  bindWindowToMarker = (google, marker, place, setActiveCard, lat, lng) => () => {
    const { address } = place;
    const info = this.infoWindowContent.children;
    info['place-icon'].src = 'https://png.pngtree.com/svg/20170727/b969b0a89c.svg';
    info['place-name'].textContent = address;
    info['place-address'].textContent = formatDate(firebaseDate(place));
    this.infoWindow.open(this.map, marker);
    // this.directionsButton.href = launchDirections(lat, lng);
    // this.directionsButton.target = '_blank'
    

    this.directionsButton.onclick = () => {
      const target = isMobile.pwa() ? '_top' : '_blank';
      const w = window.open(launchDirections(lat, lng), target);
      w.focus();
      // return (!w); // opens in new window/tab if allowed 
    }

    google.maps.event.addListener(this.infoWindow,'closeclick', () => {
      setActiveCard({});
    });
  }

  serviceGetLocation = (query) => {
    const request = {
      query,
      fields: ['name', 'geometry'],
    };

    return new Promise(async (result, reject) => {
      this.service.findPlaceFromQuery(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          result(results[0]);
        } else {
          reject();
        }
      });
    });
  }

  addLocation = location => {
    return new Promise(async (result, reject) => {
      const lang = 'en';
      const place = encodeURIComponent(location.address);
        try {
          // const request = `https://api.opencagedata.com/geocode/v1/json?key=5592edda80cf498886d34ca467c48ffe&q=${place}&pretty=1&no_annotations=1`;
          const request = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${place}&language=${lang}&inputtype=textquery&fields=photos,formatted_address,name,rating,opening_hours,geometry&key=AIzaSyAwE54uIT3Gg4VD-PMybTwHmmANTl3UwVQ`;

          console.log(request);
          
          
          const { data } = await axios.get(request,  { crossdomain: true });

          if (data.candidates.length) {
            const { geometry, formatted_address } = data.candidates[0];
            return result({
              ...location,
              address: formatted_address,
              location: geometry.location,
            });
          }
          return result(location);
        } catch (error) {
          return reject(error)
        }
    });
  }
}

const googleMap = new GoogleMap();

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const p = 0.017453292519943295;    // Math.PI / 180
  const c = Math.cos;
  const a = 0.5 - c((lat2 - lat1) * p)/2 + 
          c(lat1 * p) * c(lat2 * p) * 
          (1 - c((lon2 - lon1) * p))/2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

const launchDirections = (lat, lng) => {
    // Detect type of device to prepare URL for map directions
    switch(true) {
        case (/ipad|iphone|ipod/i.test(navigator.userAgent.toLowerCase())):
           return `https://maps.google.com/?saddr=Current+Location&daddr=${lat},${lng}`;
        case (/windows phone 7/i.test(navigator.userAgent.toLowerCase())):
           return `maps:${lat},${lng}`;
        case (/windows phone 8/i.test(navigator.userAgent.toLowerCase())):
           return `ms-drive-to:?destination.latitude=${lat}&destination.longitude=${lng}`;
        case (/android/i.test(navigator.userAgent.toLowerCase())):
          //  return `google.navigation:q=${lat},${lng}`;
          return `https://maps.google.com/?saddr=Current+Location&daddr=${lat},${lng}`;
        case (/blackberry/i.test(navigator.userAgent.toLowerCase())):
           return `javascript:blackberry.launch.newMap({'nav_end':{'latitude':${lat},'longitude':${lng}}})`;
        default:
           return `https://maps.google.com?daddr=${lat},${lng}`;
    }
}

const placePointsOnMap = (data, dispatch, { setActiveCard }) => {
  const markers = data.filter(p => p.location).map(p => ({ ...p, ...p.location }))
  const setActiveCardAction = place => dispatch(setActiveCard(place));
  googleMap.addMarkers(markers, setActiveCardAction);
}

export { googleMap, congregationGeolocaiton, calculateDistance, placePointsOnMap };