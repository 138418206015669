import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SnackBar from './snack';
import { commonOperations } from '../../state/ducks/Common';

const Toast = props => {
  const { snack, toggleSnack } = props;
  return (
    <SnackBar
      open={Boolean(snack)}
      message={snack}
      handleClose={() => toggleSnack('')}
    />
  );
}

Toast.propTypes = {
  snack: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  toggleSnack: PropTypes.func.isRequired,
};

Toast.defaultProps = {
  snack: '',
};

const mapStateToProps = ({ common }) => common;

const mapDispatchToProps = {
  toggleSnack: commonOperations.toggleSnack,
}

const Snack = connect(
  mapStateToProps,
  mapDispatchToProps
)(Toast);

export { Snack };