const fonts = {
	sm: 12,
	md: 18,
	lg: 28,
	xl: 44,
	rm: '1rem',
	primary: '-apple-system',
	lc: 'lowercase',
	up: 'uppercase',
	cp: 'capitalize',
};

const weight = {
	nm: '400',
	md: '500',
	smb: '600',
	bd: '700',
	hv: '900',
};

export { fonts, weight };
