import { createMuiTheme } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';
import { colors, fonts, weight } from '.';

const pallette = (light, main, dark) => ({
	light,
	main,
	dark
});

const typography = {
	useNextVariants: true,
	fontWeightLight: weight.bd,
	fontWeightRegular: weight.bd,
	fontWeightMedium: weight.bd,
	subtitle1: {
		color: colors.black,
		fontSize: 18,
	},
	button: {
		textTransform: fonts.cp,
	},
	p: {
		fontWeight: weight.bd,
	},
	fontFamily: [
		fonts.primary,
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"'
	].join(','),
};

const theme = createMuiTheme({
	props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
	// shadows: new Array(25),
	typography,
	palette: {
    primary: pallette(blue[300], blue.A400, blue[600]),
    secondary: pallette(orange[300], orange.A700, blue.A400),
  },
  status: orange,
});

// theme.shadows[26] = '0 12px 14px -4px rgba(209,212,226,0.40)';
const shadow = '0 1px 4px 0 rgba(0,0,0,.1), inset 0 0 0 0 #f2f2f2';
theme.shadows[24] = shadow;
theme.shadows[26] = shadow;

export { theme, shadow };