import React from 'react';
import classnames from 'classnames';
import Proptypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const CenterAlign = ({ children, classes, className, gridClassName }) => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justify="center"
    className={classnames(classes.container, className)}
  >
    <Grid className={gridClassName} item xs={12}>
      {children}
    </Grid>
  </Grid>
);

CenterAlign.propTypes = {
  children: Proptypes.node.isRequired,
  classes: Proptypes.object.isRequired,
  className: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.object,
  ]),
  gridClassName: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.object,
  ]),
}

CenterAlign.defaultProps = {
  gridClassName: '',
  className: '',
}

const Center = withStyles(styles)(CenterAlign);

export { Center }