import PropTypes from 'prop-types';
import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';
import { Center } from '..';
import { translate } from '../../utils';

const Error = (props) => {
  const { title, message } = props;
  return (
    <Center>
      <ErrorIcon color="secondary" fontSize="large" />
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {message}
      </Typography>
    </Center>
  )
}

Error.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
}

Error.defaultProps = {
  title: translate('error_occured'),
  message: translate('try_again'),
}

export { Error };