import { layout, colors, padding, weight } from "../../styles";

const border = color => `1px solid ${color}`;
const boxShadow = color => `0 1px 4px 0 ${color}, inset 0 0 0 0 #f2f2f2`;

export const notes = {
  color: '#979797',
  fontSize: '0.9rem',
  fontWeight: weight.md,
  fontStyle: 'italic'
}

export default theme => ({
  card: {
    // maxWidth: 400,
    // border: border('rgba(110, 110, 110, 0.1)'),
    flex: 1
  },
  cardSelected: {
    extends: 'card',
    border: `${border(colors.green)} !important`,
    boxShadow: `${boxShadow(colors.green)} !important`,
    // boxShadow: 'none',
  },
  cardActive: {
    extends: 'card',
    // border: border(theme.palette.secondary.main),
    boxShadow: boxShadow(theme.palette.secondary.main),
  },
  cardContent: {
    paddingTop: 0,
  },
  cardHeader: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  actions: {
    paddingTop: 0,
  },
  base: {
    textAlign: layout.lt,
    display: layout.bl,
    width: layout.fs,
    height: 124
  },
  contentContainer: {
    display: layout.fx,
    alignItems: layout.ct
  },
  placeIcon: {
    paddingRight: 4,
  },
  deleteMenuOption: {
    color: colors.red,
  },
  menuButton: {
    marginTop: 8
  },
  notesContainer: {
    paddingLeft: padding.md,
    paddingRight: padding.md
  },
  notesAction: {
    width: layout.fs
  }
});