import React from 'react';
import MapIcon from '@material-ui/icons/Map';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Auth } from '../../services';

const Logo = props => {
  const { history, congregationId , styles} = props;

  const onLogoClick = () => {
    history.push(`/${congregationId}`);
    Auth.logout();
  }

  return (
    <IconButton onClick={onLogoClick} color="inherit" aria-label="Menu" style={{ ...styles, padding: 6 }}>
      <MapIcon color="primary" style={{ fontSize: 30 }} />
    </IconButton>
  )
}

Logo.propTypes = {
  history: PropTypes.object.isRequired,
  congregationId: PropTypes.string.isRequired,
  styles: PropTypes.object,
}

Logo.defaultProps = {
  styles: {},
}

const mapStateToProps = ({ common }) => common;

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
);

export default enhance(Logo);